package vegasful.admin.loaders

import androidx.compose.runtime.*
import kotlinx.browser.localStorage
import kotlinx.datetime.Clock
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalTime
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.LoaderExecutionInput
import vegasful.admin.api.client.Tag
import vegasful.admin.components.*
import vegasful.admin.views.venuesSearch

@Composable
fun loaderDataEditor(loaderId: String, lastLoad: List<LoaderEvent>?) {
    var events by remember { mutableStateOf(lastLoad.orEmpty()) }
    var tags by remember { mutableStateOf<Map<String, Tag>>(emptyMap()) }

    LaunchedEffect(loaderId) {
        val loaderData = localStorage.getItem("loader-data-$loaderId")
        if (loaderData != null) {
            try {
                events = Json.decodeFromString(ListSerializer(LoaderEvent.serializer()), loaderData).sortedBy {
                    it.id
                }
            } catch (e: Throwable) {
                // do nothing
            }
        }
    }

    LaunchedEffect(events) {
        if (events.isNotEmpty()) {
            val str = Json.encodeToString(ListSerializer(LoaderEvent.serializer()), events)
            localStorage.setItem("loader-data-$loaderId", str)
        }
    }

    suspend fun submit(submitData: String) {
        try {
            Application.api.admin {
                loaders {
                    loader(loaderId) {
                        execute(LoaderExecutionInput(submitData))
                    }
                }
            }.loaders.loader.execute
            localStorage.removeItem("loader-data-$loaderId")
        } catch (e: Throwable) {
            Application.notifications.showError("Loader execution failed: ${e.message}")
        } finally {
        }
    }

    suspend fun saveEvents(events: List<LoaderEvent>) {
        val str = Json.encodeToString(ListSerializer(LoaderEvent.serializer()), events)
        submit(str)
    }


    box({
        title = "Events Editor"

        action {
            title = "Add Event"
            primary = true
            this.action {
                events = events + LoaderEvent(
                    "event-${Clock.System.now().epochSeconds}"
                )
            }
        }
        if (events.isNotEmpty()) {
            action {
                title = "Save"
                primary = true
                action {
                    saveEvents(events)
                }

            }
        }
    }) {
        eventsEditor(events, true) {
            events = it.orEmpty()
        }
    }
}

@Composable
fun eventsEditor(events: List<LoaderEvent>, editable: Boolean, updater: (List<LoaderEvent>?) -> Unit) {
    var expanded by remember { mutableStateOf<List<String>>(emptyList()) }

    events.forEach { event ->
        Div {
            if (expanded.contains(event.id)) {
                icon("expand_more") {
                    action {
                        expanded = expanded - event.id
                    }
                }
            } else {
                icon("chevron_right") {
                    this.action {
                        expanded = expanded + event.id
                    }
                }
            }
            Text(event.id)
        }
        if (expanded.contains(event.id)) {
            eventEditor(event, editable) { update ->
                if (update == null) {
                    updater(events - event)
                } else {
                    if (event.id != update.id) {
                        expanded = expanded - event.id + update.id
                    }
                    updater(events.replace(event, update))
                }
            }
        }
    }
}

@Composable
fun eventEditor(event: LoaderEvent, editable: Boolean = true, updater: (LoaderEvent?) -> Unit) {
    var updated by remember { mutableStateOf(event) }

    LaunchedEffect(updated) {
        updater(updated)
    }

    box({
        if (editable) {
            action {
                title = "Remove"
                primary = false
                action {

                }
            }

            action {
                title = "Add Schedule"
                primary = true
                action {
                    updated = updated.copy(schedule = updated.schedule.orEmpty() + ScheduleDescriptor(emptyList()))
                }
            }
        }
    }) {
        dialogField("ID") {
            simpleTextField(updated.id) {
                if (it != null) {
                    updated = updated.copy(id = it)
                }
            }
        }

        if (updated.name != null || editable) {
            dialogField("Name") {
                simpleTextField(updated.name) {
                    updated = updated.copy(name = it)
                }
            }
        }

        if (updated.venue != null || editable) {
            dialogField("Venue") {
                if (editable) {
                    updated.venue?.id?.let {
                        Div {
                            Text(it)
                        }
                    }
                    venuesSearch({ true }) {
                        println("Updating to ${it.name}")
                        updated = updated.copy(venue = LoaderVenue(it.id))
                    }
                } else {
                    Text(updated.venue?.id ?: "-")
                }
            }
        }

        if (updated.description != null || editable) {
            dialogField("Description") {
                richTextEditor(updated.description) {
                    updated = updated.copy(description = it)
                }
            }
        }

        if (updated.name != null || editable) {
            dialogField("Image") {
                simpleTextField(updated.images?.firstOrNull()?.url) {
                    updated = updated.copy(
                        images = if (it != null) {
                            listOf(
                                LoaderImage(url = it)
                            )
                        } else emptyList()
                    )
                }
            }
        }

        if (updated.tags != null || editable) {
            dialogField("Tags", "Comma separated list of tag ids") {
                simpleTextField(updated.tags.orEmpty().joinToString(",")) {
                    if (it != null) {
                        updated = updated.copy(tags = it.split(",").filter { it.isNotBlank() })
                    } else {
                        updated = updated.copy(tags = null)
                    }
                }
            }
        }

        updated.schedule?.forEach { schedule ->
            scheduleEditor(schedule) {
                if (it == null) {
                    updated = updated.copy(schedule = updated.schedule.orEmpty() - schedule)
                } else {
                    updated = updated.copy(schedule = updated.schedule.orEmpty().replace(schedule, it))
                }
            }
        }
    }
}

@Composable
fun scheduleEditor(schedule: ScheduleDescriptor, update: (ScheduleDescriptor?) -> Unit) {
    var updated by remember { mutableStateOf(schedule) }
    var timesInput by remember { mutableStateOf<String?>(schedule.times.joinToString(",")) }

    LaunchedEffect(timesInput) {
        try {
            updated = updated.copy(times = timesInput?.split(",")?.map {
                LocalTime.parse(it)
            }.orEmpty())
        } catch (e: IllegalArgumentException) {
            // do nothing
        }
    }

    LaunchedEffect(updated) {
        update(updated)
    }

    box({
        title = "Schedule"
    }) {
        dialogField("Times", "Enter times in 24hr format. ie. 20:45, included padded hours (04)") {
            simpleTextField(timesInput) {
                timesInput = it
            }
        }

        dialogField("Days") {
            DayOfWeek.values().forEach { dow ->
                simpleCheckBox(dow.name, updated.daysOfWeek.contains(dow.ordinal)) {
                    val updatedDays = if (it) {
                        updated.daysOfWeek + dow.ordinal
                    } else {
                        updated.daysOfWeek - dow.ordinal
                    }
                    updated = updated.copy(daysOfWeek = updatedDays)
                }
            }
        }

        dialogField("Schedule Days", "The number of days from today to schedule") {
            numberField(schedule.repeatFor) {
                updated = updated.copy(repeatFor = (it ?: 0).toInt())
            }
        }
        dialogField("Schedule End", "The date that repeats end.") {
            dateField(schedule.endDate) {
                updated = updated.copy(endDate = it)
            }
        }
    }
}

fun <T> List<T>.replace(value: T, replacement: T): List<T> {
    return this.map {
        if (value == it) {
            replacement
        } else {
            it
        }
    }
}