package vegasful.admin.components

import androidx.compose.runtime.Composable
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Theme

object DialogStyles : StyleSheet() {
    val dialog by style {
        maxWidth(800.px)
    }
    val field by style {
        marginBottom(20.px)
        type("label").style {
            fontWeight("bold")
        }
        type("input[type=text]").style {
            width(100.percent)
        }
        type("input[type=password]").style {
            width(100.percent)
        }

        type("textarea").style {
            width(100.percent)
        }
    }

    val sideBySide by style {
        display(DisplayStyle.Flex)
        gap(1.cssRem)

        child(self, className(field)).style {
            flexGrow(1)
        }
    }

    val checkbox by style {
        display(DisplayStyle.Flex)
        lineHeight((1.7).cssRem)
        type("label").style {
            fontWeight("normal")
            marginLeft((0.5).cssRem)
        }
        type("input").style {
            marginTop((0.33).cssRem)
        }
    }

    val fieldInstruction by style {
        fontSize((0.8).cssRem)
        marginTop((0.5).cssRem)
        color(Theme.subtleTextColor.value())
    }

    val fieldContent by style {
        paddingTop((0.5).cssRem)
    }

    val radioGroupOption by style {
        display(DisplayStyle.Flex)
        marginBottom((0.4).cssRem)
        gap((0.4).cssRem)
    }

    val radioGroupOptionDescription by style {
        fontSize((0.8).cssRem)
        marginTop(3.px)
        color(Theme.subtleTextColor.value())
    }


}

class DialogViewConfiguration : EntityViewConfiguration() {

}

@Composable
fun dialogView(title: String?, builder: DialogViewConfiguration.() -> Unit) {
    val config = DialogViewConfiguration().apply {
        this.title = title
        type = EntityViewType.DIALOG
        builder()
    }

    Div({
        classes(DialogStyles.dialog)
    }) {
        entityView(config)
    }
}

@Composable
fun dialogField(title: String?, instruction: String? = null, content: @Composable () -> Unit) {
    Div({ classes(DialogStyles.field) }) {
        if (title != null) {
            Label {
                Text(title)
            }
        }
        Div({ classes(DialogStyles.fieldContent) }) {
            content()
        }
        if (instruction != null) {
            Div({ classes(DialogStyles.fieldInstruction) }) {
                Text(instruction)
            }
        }
    }
}

@Composable
fun simpleTextField(value: String?, update: (String?) -> Unit) {
    Input(InputType.Text) {
        value(value.orEmpty())
        onInput {
            val newValue = it.value.ifBlank { null }
            update(newValue)
        }
    }
}


@Composable
fun numberField(value: Number?, update: (Number?) -> Unit) {
    Input(InputType.Number) {
        value(value?.toString() ?: "")
        onInput {
            val newValue = it.value
            update(newValue)
        }
    }
}

@Composable
fun dateField(value: LocalDate?, update: (LocalDate?) -> Unit) {
    Input(InputType.Date) {
        value(value?.toString() ?: "")
        onInput {
            val newValue = it.value
            if (newValue.isNotBlank()) {
                update(LocalDate.parse(newValue))
            } else {
                update(null)
            }
        }
    }
}

@Composable
fun overrideableTextField(value: String?, overrideValue: String?, update: (String?) -> Unit) {
    simpleTextField(
        value ?: overrideValue
    ) {
        update(it.takeIf { it != overrideValue })
    }
}

@Composable
fun simpleCheckBox(label: String, checked: Boolean, update: (Boolean) -> Unit) {
    Div({ classes(DialogStyles.checkbox) }) {
        Input(InputType.Checkbox) {
            this.checked(checked)
            this.onInput {
                update(it.value)
            }
        }

        Label {
            Text(label)
        }
    }
}

@Composable
fun sideBySideFields(block: @Composable () -> Unit) {
    Div({
        classes(DialogStyles.sideBySide)
    }) {
        block()
    }
}