package vegasful.admin

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.components.*
import vegasful.admin.views.SearchStyles
import vegasful.admin.views.TagsStyles
import vegasful.admin.views.contentEditor

object HeaderStyles : StyleSheet() {
    val header by style {
        height(40.px)
        backgroundColor(Color.black)
        color(Color.white)

        display(DisplayStyle.Flex)

        position(Position.Relative)
        boxShadow("0px 3px 6px rgba(0,0,0,.4)")
        property("z-index", "1")

        child(self, type("div")).style {
            flexGrow(1)
        }
    }

    val mainTitle by style {
        paddingLeft(1.em)
        lineHeight(40.px)
        fontWeight("bold")
    }

    val accountActions by style {
        lineHeight(40.px)
        textAlign("right")
        paddingRight(10.px)

        fontSize((0.8).cssRem)

        type("a").style {
            color(Color.white)

            self + hover style {
                color(Theme.highlightColor.value())
                textDecoration("none")
            }
        }
    }
}

@Composable
fun renderPage(route: Route) {
    // Install the global styles. This is a topic of improvement for Compose Web.
    Style(HeaderStyles)
    Style(EntityViewStyles)
    Style(DialogStyles)
    Style(DetailBoxStyles)
    Style(ButtonStyles)
    Style(Icons)
    Style(PaginatorStyles)
    Style(BoxStyles)
    Style(BreadCrumbStyles)
    Style(MessageBoxStyles)
    Style(NotificationStyles)

    Style(SearchStyles)
    Style(TagsStyles)
    Style(LayoutStyles)

    Application.notifications = notifications()

    Div {
        Div({
            classes(HeaderStyles.header)
        }) {
            Div({ classes(HeaderStyles.mainTitle) }) {
                Text("Vegasful Management Console")
            }

            Div({ classes(HeaderStyles.accountActions) }) {
//                Application.viewer.email?.let {
//                    Span({
//                        style {
//                            marginRight(10.px)
//                        }
//                    }) {
//                        Text(it)
//                    }
//                }

                A(href = "#", {
                    onClick {
                        it.preventDefault()
                        Application.cognito.logout()
                    }
                }) {
                    Text("Sign Out")
                }
            }
        }

        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                position(Position.Relative)
                minHeight("calc(100vh - 40px)")
            }
        }) {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Stretch)
                    flex(1, 1)
                    flexBasis("auto")
                    backgroundColor(Theme.mainLayoutBackgroundColor.value())
                }
            }) {
                primaryContentArea {
                    route.switch {
                        select("content") {
                            switch {
                                default {
                                    contentEditor(this)
                                }
                            }
                        }
                        default {
                            contentEditor(this)
                        }
                    }
                }
            }
        }
    }
}