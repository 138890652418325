package vegasful.admin.views

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import vegasful.admin.Application
import vegasful.admin.api.AddressInput
import vegasful.admin.api.GPSCoordinatesInput
import vegasful.admin.api.VenueStatus
import vegasful.admin.api.VenueUpdateInput
import vegasful.admin.api.client.*
import vegasful.admin.components.*


object SocialLinks {
    const val WEB = "web"
    const val PHONE = "phone"
    const val FACEBOOK = "facebook"
    const val TWITTER = "twitter"
    const val INSTAGRAM = "instagram"
    const val OPENTABLE = "opentable"
    const val EMAIL = "email"
}

fun _VenueQuery.parentQuery() {
    this.id
    this.name
    this.description
    this.path
    this.status
    this.links {
        this.type
        this.locator
    }
    location {
        latitude
        longitude
    }
    address {
        street
        city
        state
        country
        zip
    }
}

private fun _AdminVenueLayerQuery.venueLayerQuery() {
    this.name
    this.ticketsUrl
    this.description
    this.status
    this.rankingScore
    this.address {
        this.city
        this.zip
        this.state
        this.street
    }
    this.images {
        this.id
        this.height
        this.width
        this.uri

        this.withWidth(256) {
            this.uri
        }
    }
    this.tags {
        this.id
        this.name
    }
    this.aliases
    this.redirect {
        this.id
    }
    this.location {
        this.latitude
        this.longitude
    }
    this.links {
        this.type
        this.locator
    }
}

@Composable
fun venue(venueId: String?, parentId: String? = null) {
    var venue by remember { mutableStateOf<AdminVenue?>(null) }
    var localVenue by remember { mutableStateOf<AdminVenueLayer?>(null) }
    var parent by remember { mutableStateOf<Venue?>(null) }
    var children by remember { mutableStateOf<List<Venue>?>(null) }

    suspend fun updateVenue(update: VenueUpdateInput) {
        if (venueId != null) {
            Application.mutate {
                admin {
                    venues {
                        venue(venueId) {
                            local {
                                update(update) {
                                    venueLayerQuery()
                                }
                            }
                        }
                    }
                }
            }.admin.venues.venue.local.update.let {
                localVenue = it
            }
        }
    }

    LaunchedEffect(venueId) {
        localVenue = null
        parent = null
        children = null
        if (venueId != null) {
            Application.mutate {
                admin {
                    venues {
                        venue(venueId) {
                            this.id
                            layer("base") {
                                venueLayerQuery()
                            }

                            this.parent {
                                parentQuery()
                            }

                            children {
                                this.id
                                this.name
                                this.path
                            }
                        }
                    }
                }
            }.admin.venues.venue.let {
                venue = it
                parent = it.parent
                children = it.children
                localVenue = it.layer
            }
        } else if (parentId != null) {
            Application.query {
                venues {
                    venue(parentId) {
                        parentQuery()
                    }
                }
            }.venues.venue.let {
                parent = it
            }
        }
    }

    contentContainer {
        entityView {
            title = if (venueId == null) "New Venue" else localVenue?.name
            breadcrumbs {
                crumb("Venues", "content/venues")
            }

            if (venueId != null) {
                action {
                    this.title = "Add"
                    this.primary = true
                    this.link = "content/venues/add?parent=${venueId}"
                }
            }

            content {
                box({

                }) {
                    var newId by remember { mutableStateOf(venueId) }
                    var update by remember { mutableStateOf(VenueUpdateInput()) }

                    dialogField("ID") {
                        Input(InputType.Text) {
                            if (venueId != null) {
                                disabled()
                            }
                            value(newId.orEmpty())
                            onInput {
                                newId = it.value.ifBlank { null }
                            }
                        }
                    }

                    parent?.let { p ->
                        dialogField("Parent") {
                            A(href = "#content/venues/${p.id}") {
                                Text(p.name)
                            }
                        }
                    }

                    dialogField("Name") {
                        simpleTextField(update.name ?: localVenue?.name) {
                            update = update.copy(name = it?.takeIf { it != localVenue?.name })
                        }
                    }

                    dialogField("Description") {
                        richTextEditor(update.description ?: localVenue?.description ?: "") {
                            update = update.copy(description = it?.takeIf { it != localVenue?.description })
                        }
                    }

                    dialogField("Ranking Score") {
                        numberField(update.rankingScore ?: localVenue?.rankingScore) {
                            update = update.copy(rankingScore = it?.takeIf { it in 0..100 }?.toInt())
                        }
                    }



                    Div {
                        button("Save", true, true) {
                            if (venueId == null) {
                                if (!newId.isNullOrBlank()) {
                                    Application.mutate {
                                        admin {
                                            venues {
                                                add(newId!!, update.copy(parent = parentId)) {
                                                    this.id
                                                }
                                            }
                                        }
                                    }.admin.venues.add.id.let {
                                        Application.navigation.navigate("content/venues/${newId}")
                                    }
                                }
                            } else {
                                updateVenue(update)
                            }
                        }

                        button("Revert") {
                            update = VenueUpdateInput()
                        }
                    }
                }

                if (venueId != null) {
                    venueAliases(localVenue?.aliases.orEmpty(), object : VenueAliasUpdater {
                        override suspend fun add(alias: String) {
                            updateVenue(
                                VenueUpdateInput(
                                    aliases = localVenue?.aliases.orEmpty() + alias
                                )
                            )
                        }

                        override suspend fun remove(alias: String) {
                            updateVenue(
                                VenueUpdateInput(
                                    aliases = localVenue?.aliases.orEmpty() - alias
                                )
                            )
                        }
                    })

                    linksBox(localVenue?.links.orEmpty()) {
                        updateVenue(VenueUpdateInput(links = it))
                    }

                    box({
                        title = "Location"
                    }) {
                        var addressUpdate by remember { mutableStateOf(AddressInput()) }
                        var locationUpdate by remember { mutableStateOf(GPSCoordinatesInput()) }

                        dialogField("Street", parent?.address?.street) {
                            overrideableTextField(addressUpdate.street, localVenue?.address?.street) {
                                addressUpdate = addressUpdate.copy(street = it)
                            }
                        }

                        sideBySideFields {
                            dialogField("City", parent?.address?.city) {
                                overrideableTextField(addressUpdate.city, localVenue?.address?.city) {
                                    addressUpdate = addressUpdate.copy(city = it)
                                }
                            }
                            dialogField("Zip", parent?.address?.zip) {
                                overrideableTextField(addressUpdate.zip, localVenue?.address?.zip) {
                                    addressUpdate = addressUpdate.copy(zip = it)
                                }
                            }
                        }

                        sideBySideFields {
                            dialogField("Latitude", parent?.location?.latitude?.toString()) {
                                overrideableTextField(
                                    locationUpdate.latitude?.toString(),
                                    localVenue?.location?.latitude?.toString()
                                ) {
                                    locationUpdate = locationUpdate.copy(latitude = it?.toFloatOrNull())
                                }
                            }
                            dialogField("Longitude", parent?.location?.longitude?.toString()) {
                                overrideableTextField(
                                    locationUpdate.longitude?.toString(),
                                    localVenue?.location?.longitude?.toString()
                                ) {
                                    locationUpdate = locationUpdate.copy(longitude = it?.toFloatOrNull())
                                }
                            }
                        }

                        if (listOfNotNull(
                                addressUpdate.street,
                                addressUpdate.city,
                                addressUpdate.zip,
                                locationUpdate.latitude,
                                locationUpdate.longitude,
                            ).isNotEmpty()
                        ) {
                            Div {
                                button("Save", true) {
                                    updateVenue(
                                        VenueUpdateInput(
                                            address = addressUpdate,

                                            // both coordinates must be passed for an update.
                                            location = GPSCoordinatesInput(
                                                latitude = locationUpdate.latitude ?: localVenue?.location?.latitude,
                                                longitude = locationUpdate.longitude ?: localVenue?.location?.longitude,
                                            )
                                        )
                                    )
                                    addressUpdate = AddressInput()
                                    locationUpdate = GPSCoordinatesInput()
                                }

                                button("Revert") {
                                    addressUpdate = AddressInput()
                                    locationUpdate = GPSCoordinatesInput()
                                }
                            }
                        }
                    }

                    imagesBox(localVenue?.images.orEmpty().map {
                        ImageBoxImage(it, true)
                    }) { image, operation ->
                        when (operation) {
                            ImageBoxOperation.ADD -> {
                                updateVenue(VenueUpdateInput(
                                    images = (localVenue?.images.orEmpty() + image).map { it.id }
                                ))
                            }

                            ImageBoxOperation.REMOVE -> {
                                updateVenue(VenueUpdateInput(
                                    images = localVenue?.images.orEmpty().map { it.id } - image.id
                                ))
                            }

                            ImageBoxOperation.EXCLUDE -> {}
                            ImageBoxOperation.ENABLE -> {}
                        }

                    }

                    tagsBox(localVenue?.tags.orEmpty().map { TagBoxTag(it) }) { tag, operation ->
                        when (operation) {
                            TagOperation.ADD -> {
                                updateVenue(VenueUpdateInput(
                                    tags = (localVenue?.tags.orEmpty().map { it.id } + tag.id).distinct()
                                ))
                            }

                            TagOperation.REMOVE -> {
                                updateVenue(VenueUpdateInput(
                                    tags = (localVenue?.tags.orEmpty().map { it.id } - tag.id).distinct()
                                ))
                            }

                            TagOperation.EXCLUDE -> {

                            }

                            else -> {}
                        }
                    }

                    val hasChildren = !children.isNullOrEmpty()
                    box({
                        title = "Children"
                        paddedContent = !hasChildren

                        action("Add", true) {
                            Application.navigation.navigate("content/venues/add?parent=${venueId}")
                        }
                    }) {
                        if (hasChildren) {
                            table<Venue> {
                                items(children.orEmpty())
                                displayHeader = false
                                column("Name") {
                                    content {
                                        A(href = "#content/venues/${it.id}") {
                                            Text(it.name)
                                        }
                                    }
                                }
                            }
                        } else {
                            boxMessage("This venue contains no other venues.")
                        }
                    }
                    var statusUpdate by remember { mutableStateOf(VenueUpdateInput()) }
                    box({
                        title = "Status"
                        action {
                            title = "Save"
                            primary = true
                            showProgressOnAction = true
                            action {
                                updateVenue(statusUpdate)
                            }
                        }
                    }) {
                        dialogField("Open") {
                            Select({
                                onChange {
                                    statusUpdate = statusUpdate.copy(status = VenueStatus.valueOf(it.value!!))
                                }
                            }) {
                                val status = statusUpdate.status ?: localVenue?.status ?: VenueStatus.OPEN
                                VenueStatus.entries.forEach { option ->
                                    Option(option.name, {
                                        if (status == option) selected()
                                    }) {
                                        Text(option.name.lowercase().replace("_", " "))
                                    }
                                }
                            }
                        }

                        var venueCleared: Boolean by remember { mutableStateOf(false) }
                        dialogField("Redirect") {
                            if (venueCleared || ((statusUpdate.redirect ?: localVenue?.redirect) == null)) {
                                venuesSearch({ true }, "Venue Search:") {
                                    statusUpdate = statusUpdate.copy(redirect = it.id)
                                    venueCleared = false
                                }
                            } else {
                                (statusUpdate.redirect ?: localVenue?.redirect?.id)?.let { redirectId ->
                                    A(href = "#content/venues/${redirectId}") {
                                        Text(redirectId)
                                    }
                                    icon("delete") {
                                        action {
                                            venueCleared = true
                                            statusUpdate = statusUpdate.copy(redirect = "")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

interface VenueAliasUpdater {
    suspend fun add(alias: String)
    suspend fun remove(alias: String)
}

@Composable
fun venueAliases(aliases: List<String>, updater: VenueAliasUpdater) {
    var addingAlias: String? by remember { mutableStateOf(null) }

    box({
        title = "Aliases"
        paddedContent = false

        action("Add", true) {
            addingAlias = ""
        }

    }) {

        if (aliases.isEmpty() && addingAlias == null) {
            boxMessage("This venue has no aliases.")
        } else {
            if (addingAlias != null) {
                Div({
                    style {
                        padding(1.cssRem)
                    }
                }) {
                    dialogField("Add Alias") {
                        simpleTextField(addingAlias) {
                            addingAlias = it ?: ""
                        }
                    }
                    button {
                        title = "Save"
                        primary = true
                        action {
                            if (!addingAlias.isNullOrBlank()) {
                                updater.add(addingAlias!!)
                                addingAlias = null
                            }
                        }
                    }

                    button {
                        title = "Cancel"
                        action { addingAlias = null }
                    }
                }
            }
            table<String> {
                items(aliases)
                displayHeader = false

                column {
                    content {
                        Text(it)
                    }
                }

                column {
                    width = 100.px
                    content {
                        icon("delete") {
                            action {
                                updater.remove(it)
                            }
                        }
                    }
                }
            }
        }
    }


}