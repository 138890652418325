package vegasful.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

@Composable
fun primaryContentArea(content: @Composable () -> Unit = {}) {
    Div({
        style {
            width(100.percent)
        }
    }) {
        content()
    }
}