@file:OptIn(ExperimentalComposeWebApi::class)

package vegasful.admin.components

import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.LineStyle.Companion.Solid
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object Icons : StyleSheet() {
    val spin by keyframes {
        each(100.percent) {
            transform {
                rotate(360.deg)
            }
        }
    }

    val spinner by style {
        display(DisplayStyle.InlineBlock)
        position(Position.Relative)
        animation(spin) {
            duration(1.s)
            iterationCount(null)
            timingFunction(AnimationTimingFunction.Linear)
        }

        type("span").style {
            display(DisplayStyle.InlineBlock)
            lineHeight(40.px)
            width(40.px)
            textAlign("center")
        }
    }

    val loadingAnimation by keyframes {
        each(100.percent) {
            transform {
                rotate(360.deg)
            }
        }
    }

    val loading by style {
        display(DisplayStyle.InlineBlock)
        width(20.px)
        height(20.px)
        border(3.px, Solid, rgba(0, 0, 0, .3))
        borderRadius(50.percent)
        property("border-top-color", "#000")
        animation(spin) {
            duration(1.s)
            timingFunction(AnimationTimingFunction.EaseInOut)
            iterationCount(null)
        }
    }

    val loadingLight by style {
        border(3.px, Solid, rgba(255, 255, 255, .3))
        property("border-top-color", "#fff")
    }

    val loadingSmall by style {
        width(10.px)
        height(10.px)
    }

    val loadingLarge by style {
        width(50.px)
        height(50.px)
    }
}

class IconConfiguration(val icon: String) {
    internal var styles: StyleScope.() -> Unit = {}
    internal var action: (suspend () -> Unit)? = null

    var size: IconSize = IconSize.NORMAL

    fun style(builder: StyleScope.() -> Unit) {
        styles = builder
    }

    fun action(block: suspend () -> Unit) {
        this.action = block
    }
}

enum class IconSize(val selector: String?) {
    TINY("tiny"),
    SMALL("small"),
    NORMAL(null),
    LARGE("large"),
    GIANT("giant")
}

@Composable
fun iconAction(name: String, action: (suspend () -> Unit)) {
    icon(name) {
        this.action(action)
    }
}

@Composable
fun icon(config: IconConfiguration) {
    val coroutineScope = rememberCoroutineScope()

    Span({
        classes("material-icons")

        config.size.selector?.let {
            classes(it)
        }

        style {
            property("vertical-align", "middle")
        }

        if (config.action != null) {
            style {
                cursor("pointer")

                config.styles(this)
            }
            onClick {
                it.preventDefault()
                coroutineScope.launch {
                    config.action?.invoke()
                }
            }
        }
    }) {
        Text(config.icon)
    }
}

@Composable
fun icon(name: String, init: IconConfiguration.() -> Unit = {}) {
    icon(IconConfiguration(name).apply(init))
}

enum class Style {
    LIGHT,
    DARK
}

enum class Size {
    SMALL,
    NORMAL,
    LARGE
}

@Composable
fun spinner(style: Style = Style.DARK, size: Size = Size.NORMAL, delay: Long = 0) {
    var visible: Boolean by mutableStateOf(false)

    LaunchedEffect(true) {
        if (delay > 0) {
            delay(delay)
        }
        visible = true
    }

    Div({
        classes(Icons.loading)

        if (style == Style.LIGHT) {
            classes(Icons.loadingLight)
        }

        when (size) {
            Size.SMALL -> classes(Icons.loadingSmall)
            Size.LARGE -> classes(Icons.loadingLarge)
            else -> {}
        }

        style {
            if (visible) {
                this.property("visibility", "visible")
            } else {
                this.property("visibility", "hidden")
            }
        }
    })
}