package vegasful.admin.views

import androidx.compose.runtime.Composable
import vegasful.admin.Application
import vegasful.admin.api.client.Performer
import vegasful.admin.components.box

@Composable
fun performers() {
    contentContainer {
        box({
            this.header({
                title = "Performers"
                paddedContent = false

                this.action {
                    title = "New Performer"
                    primary = true
                    action {
                        Application.navigation.navigate("content/performers/add")
                    }
                }
            })
        }) {
            entitySearch<Performer> {
                style = SearchStyle.BOX
                withEntityResult {
                    performers {
                        id
                        name
                        path
                    }
                }
                selection = {
                    Application.navigation.navigate("content/performers/${it.id}")
                }
            }
        }
    }
}