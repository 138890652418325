package vegasful.admin.components

import androidx.compose.runtime.Composable
import vegasful.admin.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

object MessageBoxStyles : StyleSheet() {
    val messageBox by style {
        border(1.px, LineStyle.Solid, Theme.messageInfoBorder.value())
        padding((1.4).cssRem, (2.0).cssRem)
        display(DisplayStyle.Flex)
        gap((1.0).cssRem)

        marginBottom((1.0).cssRem)
    }

    val icon by style {

    }

    val info by style {
        backgroundColor(Theme.messageInfoBackground.value())
        property("border-color", Theme.messageInfoBorder.value())

        child(self, className(icon)) style {
            color(Theme.messageInfoBorder.value())
        }

    }
    val warn by style {
        backgroundColor(Theme.messageWarnBackground.value())
        property("border-color", Theme.messageWarnBorder.value())
    }
    val alert by style {
        backgroundColor(Theme.messageAlertBackground.value())
        property("border-color", Theme.messageAlertBorder.value())
    }

}


enum class MessageType(val className: String, val icon: String) {
    INFO(MessageBoxStyles.info, "help_outline"),
    WARNING(MessageBoxStyles.warn, "warning"),
    ALERT(MessageBoxStyles.alert, "error")
}

@Composable
fun messageBox(message: String, type: MessageType = MessageType.INFO) {
    messageBox(type) {
        Text(message)
    }
}

@Composable
fun messageBox(type: MessageType = MessageType.INFO, content: @Composable () -> Unit) {
    Div({
        classes(MessageBoxStyles.messageBox)
        classes(type.className)
    }) {
        Div({ classes(MessageBoxStyles.icon) }) {
            icon(type.icon)
        }
        Div {
            content()
        }
    }
}