package vegasful.admin.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

class ModuleConfiguration(val close: () -> Unit) {
    var nav: (@Composable () -> Unit)? = null
    var content: (@Composable () -> Unit)? = null

    fun navigation(content: @Composable () -> Unit) {
        nav = content
    }

    fun content(content: @Composable () -> Unit) {
        this.content = content
    }
}

@Composable
fun module(block: ModuleConfiguration.() -> Unit) {
    var expanded by remember { mutableStateOf(false) }

    val config = ModuleConfiguration {
        expanded = false
    }.apply(block)

    Div(attrs = {
        style {
            position(Position.Relative)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Stretch)
            overflow("hidden")
            height(100.percent)
        }

    }) {
        if (config.nav != null) {
            Div(attrs = {
                style {
                    backgroundColor(Color.white)
                    property("box-shadow", "0px 1px 1px rgba(0,0,0,.2)")
                }
            }) {
                if (expanded) {
                    Div {
                        config.nav!!()
                    }
                } else {
                    Div({
                        style {
                            width(3.cssRem)
                            textAlign("center")
                            marginTop(1.cssRem)
                        }
                    }) {
                        icon("menu") {
                            action {
                                expanded = true
                            }
                        }
                    }
                }
            }
        }
        if (config.content != null) {
            Div({
                style {
                    flexGrow(1)
                }

            }) {
                config.content!!()

            }
        }

    }
}