package vegasful.admin.views

import androidx.compose.runtime.Composable
import vegasful.admin.Application
import vegasful.admin.api.client.Venue
import vegasful.admin.components.box

@Composable
fun venues() {
    contentContainer {
        box({
            this.header({
                title = "Venues"
                paddedContent = false
            }) {

            }
        }) {
            entitySearch<Venue> {
                style = SearchStyle.BOX
                withEntityResult {
                    venues {
                        id
                        name
                        path
                    }
                }
                selection = { venue ->
                    Application.navigation.navigate("content/venues/${venue.id}")
                }
            }
        }
    }
}

@Composable
fun venuesSearch(filter: (Venue) -> Boolean, label: String? = null, selection: suspend (Venue) -> Unit) {
    entitySearch<Venue> {
        withEntityResult {
            venues {
                this.id
                this.name
                this.path
            }
        }
        this.filter = filter
        this.selection = selection
        if (label != null) {
            this.searchText = label
        }
    }
}