package vegasful.admin.views

import vegasful.admin.api.client._ImageQuery

fun _ImageQuery.imageThumbnailQuery() {
    this.id
    this.height
    this.width
    this.uri

    this.withWidth(256) {
        this.uri
    }
}