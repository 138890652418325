package vegasful.admin.views

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import vegasful.admin.Route
import vegasful.admin.Theme
import vegasful.admin.components.entityView
import vegasful.admin.components.icon
import vegasful.admin.components.module
import vegasful.admin.loaders.loader
import vegasful.admin.loaders.loaders

enum class Section(val title: String, val path: String) {
    EVENTS("Events", "content/events"),
    VENUES("Venues", "content/venues"),
    PERFORMERS("Performers", "content/performers"),
    TAGS("Tags", "content/tags"),
    LOADERS("Loaders", "content/loaders"),
    ARTICLES("Articles", "content/articles")
}

@Composable
fun contentEditor(route: Route) {
    module {
        navigation {
            Div({
                style {
                    position(Position.Relative)
                }
            }) {
                Div({
                    style {
                        position(Position.Absolute)
                        top(14.px)
                        right(15.px)
                        opacity(.5)
                    }
                }) {
                    icon("close") {
                        action {
                            this@module.close()
                        }
                    }
                }

                Div({
                    style {
                        padding(17.px, 50.px, 17.px, 20.px)
                        fontWeight("bold")
                        fontSize(1.2.cssRem)
                        border(0.px, LineStyle.Solid, Theme.defaultDivider.value())
                        borderWidth(0.px, 0.px, 1.px, 0.px)

                    }
                }) {
                    Text("Content")
                }

                Ul({
                    style {
                        listStyle("none")
                        padding(0.px, 20.px)
                    }
                }) {
                    Section.entries.forEach { section ->
                        Li({
                            style {
                                margin(10.px, 0.px)
                            }
                        }) {
                            A("#${section.path}") {
                                Text(section.title)
                            }
                        }
                    }

                }
            }
        }

        content {
            val section = route.match.takeIf { it.isNotBlank() }?.let {
                Section.valueOf(it.uppercase())
            }
            Div {
                when (section) {
                    Section.VENUES -> {
                        route.switch {
                            select("add") {
                                venue(null, this.parameter("parent"))
                            }
                            default {
                                if (this.match.isBlank()) {
                                    venues()
                                } else {
                                    venue(this.match)
                                }
                            }
                        }
                    }

                    Section.EVENTS -> {
                        route.switch {
                            default {
                                if (this.match.isBlank()) {
                                    events()
                                } else {
                                    event(this.match)
                                }
                            }
                        }
                    }

                    Section.PERFORMERS -> {
                        route.switch {
                            select("add") {
                                performer(null, parameter("event"), parameter("name"))
                            }
                            default {
                                if (this.match.isBlank()) {
                                    performers()
                                } else {
                                    performer(this.match)
                                }
                            }
                        }
                    }

                    Section.TAGS -> {
                        route.switch {
                            select("add") {
                                tag(null)
                            }
                            default {
                                if (this.match.isBlank()) {
                                    tags()
                                } else {
                                    tag(this.match)
                                }
                            }
                        }
                    }

                    Section.LOADERS -> {
                        route.switch {
                            select("add") {
                                loader(null)
                            }
                            default {
                                if (this.match.isBlank()) {
                                    loaders()
                                } else {
                                    loader(this.match)
                                }
                            }
                        }
                    }

                    Section.ARTICLES -> {
                        route.switch {
                            select("add") {
                                article(null)
                            }
                            default {
                                if (this.match.isBlank()) {
                                    articles()
                                } else {
                                    article(this.match)
                                }
                            }
                        }
                    }

                    else -> {
                        contentHome()
                    }
                }
            }

        }

    }
}

@Composable
fun contentHome() {
    contentContainer {
        entityView {
            title = "Vegasful Admin"

            content {
                reviewQueueBox()
            }
        }
    }
}

@Composable
fun contentContainer(block: @Composable () -> Unit) {
    Div({
        style {
            padding(16.px, 40.px)
        }

    }) {
        block()
    }

}