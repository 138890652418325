package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.autoFocus
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import vegasful.admin.Application
import vegasful.admin.Theme
import vegasful.admin.api.GlobalSearchInput
import vegasful.admin.api.client.Entity
import vegasful.admin.api.client._GlobalSearchResultsQuery
import vegasful.admin.api.client.query
import vegasful.admin.components.Size
import vegasful.admin.components.icon
import vegasful.admin.components.spinner
import vegasful.admin.components.table

object SearchStyles : StyleSheet() {
    val searchInput by style {
        padding(.5.cssRem)
        position(Position.Relative)

        backgroundColor(Theme.containerContentBackgroundColor.value())

        className("search-input").style {
            display(DisplayStyle.InlineBlock)

        }
    }

    val searchLabel by style {
        marginRight(1.cssRem)
    }

    val searchClose by style {
        position(Position.Absolute)
        top(5.px)
        right(5.px)
    }

    val pill by style {
        display(DisplayStyle.InlineBlock)
        backgroundColor(Theme.buttonBackgroundPrimary.value())
        marginRight(.3.cssRem)
        color(Theme.buttonBackgroundNormal.value())
        padding(.3.cssRem)
        fontWeight(700)
        borderRadius(5.px)

        cursor("pointer")
    }
}


/**
 * Renders a simple search input box.
 */
@Composable
fun searchInput(execute: suspend (String?) -> Unit) {
    var searchInput by remember { mutableStateOf<String?>(null) }
    var searchText by remember { mutableStateOf<String?>(null) }
    var searching by mutableStateOf(false)

    LaunchedEffect(searchText) {
        searching = true
        try {
            execute(searchText)
        } finally {
            searching = false
        }
    }

    Div({
        classes("search-input")
        style {
            paddingRight(30.px)
            position(Position.Relative)
        }
    }) {
        Input(InputType.Text) {
            autoFocus()
            defaultValue(searchInput ?: "")
            onInput {
                searchInput = it.value.ifBlank { null }
            }
            onKeyDown {
                if (it.code == "Enter") {
                    searchText = searchInput
                }
            }
        }

        if (searching) {
            Div({
                style {
                    position(Position.Absolute)
                    top(5.px)
                    right(5.px)
                }
            }) {
                spinner()
            }
        }
    }
}

class SearchConfig<T : Entity>() {
    var searchText: String = "Search:"
    lateinit var search: suspend (String) -> List<T>
    var style: SearchStyle = SearchStyle.PILL
    var filter: (T) -> Boolean = { true }
    var selection: suspend (T) -> Unit = {}

    /**
     * The initial search text. A search will be performed if this is set.
     */
    var initialSearchInput: String? = null

    var quickAdd: (@Composable () -> Unit)? = null
    var add: ((String?) -> Unit)? = null

    var onClose: (() -> Unit)? = null

    var showSpinnerOnClick = false

    private fun <T> resultOrNull(block: () -> T): T? {
        return try {
            block()
        } catch (t: Throwable) {
            null
        }
    }

    fun withEntityResult(result: _GlobalSearchResultsQuery.() -> Unit) {
        search = { input ->
            Application.api.query {
                search(GlobalSearchInput(input)) {
                    result()
                }
            }.search.let {
                val allResults = mutableListOf<Entity>()
                allResults += resultOrNull { it.performers }.orEmpty()
                allResults += resultOrNull { it.tags }.orEmpty()
                allResults += resultOrNull { it.events }.orEmpty()
                allResults += resultOrNull { it.venues }.orEmpty()

                allResults.mapNotNull {
                    @Suppress("UNCHECKED_CAST")
                    it as? T
                }
            }
        }
    }
}

/**
 * Render an entity search box.
 */
@Composable
fun <T : Entity> entitySearch(
    init: SearchConfig<T>.() -> Unit
) {
    val coroutine = rememberCoroutineScope()

    var results by remember { mutableStateOf<List<T>?>(null) }
    var showQuickAdd by remember { mutableStateOf(false) }
    var operatingOn by remember { mutableStateOf<String?>(null) }
    var searchText by remember { mutableStateOf<String?>(null) }

    val config = SearchConfig<T>()
    config.init()

    LaunchedEffect(config.initialSearchInput) {
        config.initialSearchInput?.let {
            results = config.search(it)
            searchText = it
        }
    }

    Div({ classes(SearchStyles.searchInput) }) {
        DisposableEffect(true) {
            (scopeElement.getElementsByTagName("input").item(0) as? HTMLInputElement)?.focus()
            onDispose {

            }
        }

        if (config.searchText.isNotBlank()) {
            Span({ classes(SearchStyles.searchLabel) }) {
                Text(config.searchText)
            }
        }
        searchInput {
            searchText = it
            if (it != null) {
                results = config.search(it)
            }
        }

        if (config.style == SearchStyle.PILL) {
            results?.takeIf { it.isNotEmpty() }?.filter {
                config.filter(it)
            }?.forEach { entity ->
                Div({
                    classes(SearchStyles.pill)

                    onClick {
                        operatingOn = entity.id
                        coroutine.launch {
                            config.selection(entity)
                            operatingOn = null
                        }
                    }
                }) {
                    Text(entity.name.orEmpty())

                    if (config.showSpinnerOnClick && operatingOn == entity.id) {
                        spinner(size = Size.SMALL, style = vegasful.admin.components.Style.LIGHT)
                    }
                }
            }

            if (config.quickAdd != null || config.add != null) {
                Div({
                    classes(SearchStyles.pill)
                    onClick {
                        if (config.quickAdd != null) {
                            showQuickAdd = true
                        } else if (config.add != null) {
                            config.add?.invoke(searchText)
                        }
                    }
                }) {
                    Text("+")
                }
            }
        }

        if (config.onClose != null) {
            Div({ classes(SearchStyles.searchClose) }) {
                icon("close") {
                    action {
                        config.onClose?.invoke()
                    }
                }
            }
        }
    }

    if (config.style == SearchStyle.BOX) {
        table<T> {
            items(results.orEmpty())
            column {
                content { entity ->
                    A(href = "#", {
                        onClick {
                            it.preventDefault()
                            coroutine.launch {
                                config.selection(entity)
                            }
                        }
                    }) {
                        Text(entity.name.orEmpty())
                    }
                }
            }
        }
    }

    if (showQuickAdd) {
        config.quickAdd?.invoke()
    }
}