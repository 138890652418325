package vegasful.admin.views

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.Div
import vegasful.admin.api.SocialLinkInput
import vegasful.admin.api.client.SocialLink
import vegasful.admin.components.*

@Composable
fun linksBox(baseLinks: List<SocialLink>, update: suspend (List<SocialLinkInput>) -> Unit) {
    box({
        title = "Links"
    }) {
        var updates: List<SocialLinkInput> by remember { mutableStateOf(emptyList()) }

        @Composable
        fun linkField(name: String, type: String) {
            dialogField(name) {
                simpleTextField(
                    updates.find { it.type == type }?.locator
                        ?: baseLinks.find { it.type == type }?.locator
                ) {
                    updates = updates.filterNot { it.type == type } + SocialLinkInput(type, it)
                }
            }
        }

        sideBySideFields {
            linkField("Web Site", SocialLinks.WEB)
            linkField("Phone", SocialLinks.PHONE)
        }
        sideBySideFields {
            linkField("Facebook", SocialLinks.FACEBOOK)
            linkField("Twitter", SocialLinks.TWITTER)
        }
        sideBySideFields {
            linkField("Open Table", SocialLinks.OPENTABLE)
            linkField("Instagram", SocialLinks.INSTAGRAM)
        }


        if (!updates.isEmpty()) {
            Div {
                button {
                    title = "Save"
                    primary = true
                    showProgressOnAction = true
                    action {
                        update(updates)
                        updates = emptyList()
                    }
                }
                button("Revert") {
                    updates = emptyList()
                }
            }
        }
    }
}