package vegasful.admin.loaders

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.client.AdminLoader
import vegasful.admin.components.box
import vegasful.admin.components.entityView
import vegasful.admin.components.table
import vegasful.admin.views.contentContainer

@Composable
fun loaders() {
    var loaders by remember { mutableStateOf(emptyList<AdminLoader>()) }

    LaunchedEffect(true) {
        Application.api.admin("ReadLoaders") {
            loaders {
                loaders {
                    id
                    name
                    schedule
                }
            }
        }.loaders.loaders.let {
            loaders = it.sortedWith(compareBy(String.CASE_INSENSITIVE_ORDER) { it.name })
        }
    }

    contentContainer {
        entityView {
            title = "Loaders"

            action {
                title = "Add"

                action {
                    Application.navigation.navigate("content/loaders/add")
                }
            }

            content {
                box({
                    paddedContent = false
                }) {
                    table<AdminLoader> {
                        items(loaders)

                        column("Name") {
                            content {
                                A(href = "#content/loaders/${it.id}") {
                                    Text(it.name)
                                }
                            }
                        }

                        column("Schedule") {
                            content {
                                Text(it.schedule ?: "")
                            }
                        }
                    }
                }
            }
        }
    }
}