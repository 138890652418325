package vegasful.admin.views

import androidx.compose.runtime.*
import com.steamstreet.graphkt.client.GraphQLClient
import org.jetbrains.compose.web.css.listStyle
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import vegasful.admin.Application
import vegasful.admin.api.client.Tag
import vegasful.admin.api.client.query
import vegasful.admin.components.box
import vegasful.admin.components.icon
import vegasful.admin.components.spinner

suspend fun GraphQLClient.loadTags(parentId: String?): List<Tag> {
    return query {
        tags {
            if (parentId == null) {
                root {
                    id
                    name
                }
            } else {
                tag(parentId) {
                    tags {
                        id
                        name
                    }
                }
            }
        }
    }.tags.let {
        (if (parentId == null) {
            it.root
        } else {
            it.tag.tags
        }).orEmpty()
    }
}

@Composable
fun tags() {
    contentContainer {
        box({
            title = "Tags"
            action("Add", true) {
                Application.navigation.navigate("content/tags/add")
            }
        }) {
            tagSection(null)
        }
    }
}

@Composable
fun tagSection(parentId: String?) {
    var tags by remember { mutableStateOf<List<Tag>?>(null) }
    var expanded by remember { mutableStateOf<Set<String>>(emptySet()) }

    LaunchedEffect(parentId) {
        tags = Application.api.loadTags(parentId).sortedBy { it.name.lowercase() }
    }

    Div {
        if (tags == null) {
            spinner()
        } else if (tags?.isEmpty() == true) {
            // show nothing
        } else {
            Ul({
                style {
                    paddingLeft(0.px)
                }
            }) {
                tags?.forEach { tag ->
                    Li({
                        style {
                            listStyle("none")
                        }
                    }) {
                        val isExpanded = expanded.contains(tag.id)
                        icon(if (isExpanded) "arrow_drop_down" else "arrow_right") {
                            action {
                                if (isExpanded) {
                                    expanded = expanded - tag.id
                                } else {
                                    expanded = expanded + tag.id
                                }
                            }
                        }
                        A(href = "#content/tags/${tag.id}") {
                            Text(tag.name)
                        }
                        if (isExpanded) {
                            Div({
                                style {
                                    marginLeft(24.px)
                                }
                            }) {
                                tagSection(tag.id)
                            }
                        }
                    }
                }
            }
        }
    }
}