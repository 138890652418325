package vegasful.admin.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.ElementBuilder
import org.jetbrains.compose.web.dom.TagElement
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import vegasful.admin.Theme
import vegasful.admin.jso

external interface EditorEvent {

}

external interface GetContentOptions {
    var format: String
}

external interface Editor {
    fun on(event: String, handler: (EditorEvent) -> Unit)
    fun getContent(options: GetContentOptions?): String
}

fun GetContentOptions(format: String = "raw"): GetContentOptions = jso {
    this.format = format
}

fun Editor.getContent() = getContent(GetContentOptions())

external interface TinyMceOptions {
    var target: HTMLElement
    var init_instance_callback: (Editor) -> Unit
    var inline: Boolean
    var menubar: Boolean
    var toolbar: String
    var plugins: String?

    var autoresize_bottom_margin: Int?
}

external object tinymce {
    fun init(options: TinyMceOptions)
}

fun TinyMceOptions(block: TinyMceOptions.() -> Unit) = jso(block)


private open class ElementBuilderImplementation<TElement : Element>(private val tagName: String) :
    ElementBuilder<TElement> {
    private val el: Element by lazy { document.createElement(tagName) }

    @Suppress("UNCHECKED_CAST")
    override fun create(): TElement = el.cloneNode() as TElement
}

class TinyMCEAttrsScope(
    attrsScope: AttrsScope<HTMLElement>
) : AttrsScope<HTMLElement> by attrsScope {

    fun apiKey(key: String): TinyMCEAttrsScope = apply {
        attr("api-key", key)
    }

    fun hideMenuBar(): TinyMCEAttrsScope = apply {
        attr("menubar", "false")
    }

    fun plugins(vararg list: String): TinyMCEAttrsScope = apply {
        attr("plugins", list.joinToString(" "))
    }

    fun toolbar(defn: String): TinyMCEAttrsScope = apply {
        attr("toolbar", defn)
    }
}

@Composable
fun tinyMce(attrs: TinyMCEAttrsScope.() -> Unit = {}, content: String) = TagElement(
    ElementBuilderImplementation<HTMLElement>("tinymce-editor"), applyAttrs = {
        val tinymceAttrsBuilder = TinyMCEAttrsScope(this)
        tinymceAttrsBuilder.attrs()
    }) {
    Text(content)
}

fun Editor.getCleanedContent(): String? {
    val content = getContent()
    content.takeIf { it.isNotBlank() } ?: return null

    val withoutEnclosingP = content.removePrefix("<p>").removeSuffix("</p>")
    return if (withoutEnclosingP.contains(Regex("[<>]"))) {
        content
    } else {
        withoutEnclosingP
    }
}

@Composable
fun richTextEditor(content: String?, callback: (String?) -> Unit) {
    Div({
        style {
            border(1.px, LineStyle.Solid, Theme.tabsBorder.value())
            borderRadius(2.px)
            padding(0.px, (0.6).cssRem)
        }
    }) {
        DisposableEffect(content) {
            if (content != null) {
                scopeElement.innerHTML = content
            }

            tinymce.init(TinyMceOptions {
                target = scopeElement
                menubar = false
                plugins = "autoresize link"
                autoresize_bottom_margin = 0

                init_instance_callback = { editor ->
                    editor.on("input") {
                        callback(editor.getCleanedContent())
                    }
                    editor.on("Change") {
                        callback(editor.getCleanedContent())
                    }
                }
            })

            onDispose {

            }
        }
    }
}