package vegasful.admin.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

/**
 * Styles for the basic layout components
 */
object LayoutStyles : StyleSheet() {
    val sideBySide by style {
        display(DisplayStyle.Flex)
        gap(1.cssRem)

        child(self, universal).style {
            flex(1, 1, 0.percent)
        }
    }
}

@Composable
fun sideBySide(block: @Composable () -> Unit) {
    Div({
        classes(LayoutStyles.sideBySide)
    }) {
        block()
    }
}