package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Theme
import vegasful.admin.api.client.Tag
import vegasful.admin.components.box
import vegasful.admin.components.boxMessage
import vegasful.admin.components.icon
import vegasful.admin.components.table

object TagsStyles : StyleSheet() {
    val tagAdd by style {
        padding(.5.cssRem)

        className("search-input").style {
            display(DisplayStyle.InlineBlock)

        }
    }

    val tagPill by style {
        display(DisplayStyle.InlineBlock)
        backgroundColor(Theme.buttonBackgroundPrimary.value())
        marginRight(.3.cssRem)
        color(Theme.buttonBackgroundNormal.value())
        padding(.3.cssRem)
        fontWeight(700)
        borderRadius(5.px)

        cursor("pointer")
    }
}

enum class TagOperation {
    ADD,
    REMOVE,
    EXCLUDE,
    UNEXCLUDE,
}

class TagBoxTag(
    val tag: Tag,
    val inherited: Boolean = false,
    val excluded: Boolean = false,
    val source: String? = null
)

@Composable
fun tagsSearch(filter: (Tag) -> Boolean, selection: suspend (Tag) -> Unit) {
    entitySearch<Tag> {
        withEntityResult {
            tags {
                this.id
                this.name
                this.path
            }
        }
        this.filter = filter
        this.selection = selection
    }
}

/**
 * A box to display a list of tags and operations upon them.
 */
@Composable
fun tagsBox(tags: List<TagBoxTag>, title: String = "Tags", onUpdate: suspend (Tag, TagOperation) -> Unit) {
    val coroutine = rememberCoroutineScope()
    var adding by remember { mutableStateOf(false) }

    box({
        this.title = title
        paddedContent = tags.isEmpty()
        action("Add", true) {
            adding = true
        }
    }) {
        if (tags.isEmpty()) {
            if (!adding) {
                boxMessage("There are no tags.")
            }
        } else {
            table<TagBoxTag> {
                items(tags)
                displayHeader = false
                column {
                    content {
                        A(href = "#content/tags/${it.tag.id}") {
                            Span({
                                style {
                                    if (it.excluded) {
                                        textDecoration("line-through")
                                    }
                                }
                            }) {
                                Text(it.tag.name)
                            }
                        }
                    }
                }

                if (tags.any { it.source != null }) {
                    column {
                        content {
                            it.source?.let {
                                Text(it)
                            }
                        }
                    }
                }

                column {
                    width = 100.px
                    content { tag ->
                        Div({
                            style { textAlign("right") }
                        }) {
                            @Composable
                            fun tagAction(icon: String, operation: TagOperation) {
                                icon(icon) {
                                    action {
                                        onUpdate(tag.tag, operation)
                                    }
                                }
                            }

                            if (tag.excluded) {
                                tagAction("add", TagOperation.UNEXCLUDE)
                            } else if (tag.inherited) {
                                tagAction("block", TagOperation.EXCLUDE)
                            } else {
                                tagAction("delete", TagOperation.REMOVE)
                            }
                        }
                    }
                }
            }
        }

        if (adding) {
            tagsSearch({
                !tags.map { it.tag.id }.contains(it.id)
            }, {
                coroutine.launch {
                    onUpdate(it, TagOperation.ADD)
                }
            })
        }
    }
}