package vegasful.admin.views

import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.client.Event
import vegasful.admin.components.box
import vegasful.admin.components.boxMessage
import vegasful.admin.components.boxSpinner
import vegasful.admin.components.table

@Composable
fun events() {
    contentContainer {
        reviewQueueBox()
    }
}

/**
 * Render a box that shows some recent events that require review.
 */
@Composable
fun reviewQueueBox() {
    var eventsForReview by remember { mutableStateOf<List<Event>?>(null) }
    LaunchedEffect(true) {
        Application.api.admin("ReadReviewQueue") {
            events {
                forReview {
                    id
                    name
                    path
                    venue {
                        id
                        name
                    }
                }
            }
        }.events.forReview.let {
            eventsForReview = it
        }
    }

    box({
        title = "Review Queue"
        paddedContent = false

        action("Start Reviewing", true) {
            if (!loadNextEventForReview()) {
                Application.notifications.showError("There are no more events to review.")
            }
        }
    }) {
        if (eventsForReview == null) {
            boxSpinner("Loading events for review...")
        } else if (eventsForReview.isNullOrEmpty()) {
            boxMessage("All events have been reviewed.")
        } else {
            table<Event> {
                items(eventsForReview.orEmpty())
                column("Name") {
                    content {
                        A(href = "#content/events/${it.id}") {
                            Text(it.name)
                        }
                    }
                }
            }
        }
    }
}

/**
 * Make a request for the next event to review, and navigate to that event.
 */
suspend fun loadNextEventForReview(): Boolean {
    Application.api.admin {
        events {
            reviewNext {
                id
            }
        }
    }.events.reviewNext?.id.let { id ->
        if (id != null) {
            Application.navigation.navigate("content/events/${id}")
            return true
        } else {
            return false
        }
    }
}