package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import vegasful.admin.Application
import vegasful.admin.api.ImageUpload
import vegasful.admin.api.client.Image
import vegasful.admin.components.*

/**
 * Wraps an image along with some extra properties for the image box.
 */
data class ImageBoxImage(
    val image: Image,
    val removable: Boolean = true,
    val excludable: Boolean = true,
    val enableable: Boolean = false
)

enum class ImageBoxOperation {
    ADD,
    REMOVE,
    EXCLUDE,
    ENABLE
}

@Composable
fun imagesBox(images: List<ImageBoxImage>?, onImageUpdate: suspend (Image, ImageBoxOperation) -> Unit) {
    val coroutineScope = rememberCoroutineScope()

    var adding by remember { mutableStateOf(false) }
    var uploading by remember { mutableStateOf(false) }
    var file by remember { mutableStateOf<File?>(null) }
    var url by remember { mutableStateOf<String?>(null) }

    fun uploadImage(image: ImageUpload) {
        coroutineScope.launch {
            try {
                val uploaded = Application.mutate {
                    admin {
                        images {
                            this.upload(image) {
                                id
                                uri
                            }
                        }
                    }
                }.admin.images.upload

                onImageUpdate(uploaded, ImageBoxOperation.ADD)
                uploading = false
                adding = false
            } catch (e: Throwable) {
                uploading = false
            }
        }
    }

    /**
     * Upload an image file
     */
    fun uploadFile(file: File) {
        uploading = true
        val reader = FileReader()
        reader.onload = {
            val base64 = (it.target as FileReader).result

            uploadImage(ImageUpload(base64 as? String, null, file.type))
        }
        reader.readAsDataURL(file)
    }

    /**
     * Upload an image url
     */
    fun uploadUrl(url: String) {
        uploading = true
        coroutineScope.launch {
            uploadImage(ImageUpload(uri = url))
        }
    }

    fun removeImage(image: ImageBoxImage, operation: ImageBoxOperation) {
        coroutineScope.launch {
            onImageUpdate(image.image, operation)
        }
    }

    box({
        title = "Images"

        if (!adding) {
            action("Add", true) {
                adding = true
            }
        }
    }) {
        if (uploading) {
            Text("Adding image...")
            spinner()
        } else if (adding) {
            Div {
                if (url == null) {
                    dialogField("Select an image file") {
                        Input(InputType.File) {
                            onInput {
                                file = it.target.files?.get(0)
                                println("New file ${file?.name}")
                                url = null
                            }
                        }
                    }
                }

                if (file == null) {
                    dialogField("Or enter an image URL") {
                        simpleTextField(url) {
                            url = it.takeIf { !it.isNullOrBlank() }
                            file = null
                        }
                    }
                }

                Div {
                    button("Submit", true) {
                        if (file != null) {
                            uploadFile(file!!)
                        } else if (url != null) {
                            uploadUrl(url!!)
                        }
                    }

                    button("Cancel") {
                        adding = false
                    }
                }
            }
        } else {
            if (images.orEmpty().isEmpty()) {
                boxMessage("There are no images")
            } else {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        flexWrap(FlexWrap.Wrap)
                        gap(1.cssRem)
                    }
                }) {
                    images.orEmpty().forEach { image ->
                        Div({
                            style {
                                width(256.px)
                                position(Position.Relative)
                            }
                        }) {
                            Img(image.image.uri) {
                                style {
                                    width(100.percent)
                                }
                            }

                            if (image.removable || image.excludable || image.enableable) {
                                Div({
                                    style {
                                        position(Position.Absolute)
                                        top(5.px)
                                        right(5.px)
                                        backgroundColor(Color.white)
                                        borderRadius(25.px)
                                        property("box-shadow", "0px 0px 4px rgb(0 0 0 / 40%)")
                                        cursor("pointer")
                                    }

                                    onClick {
                                        removeImage(
                                            image, if (image.removable)
                                                ImageBoxOperation.REMOVE
                                            else if (image.enableable)
                                                ImageBoxOperation.ENABLE
                                            else ImageBoxOperation.EXCLUDE
                                        )
                                    }
                                }) {
                                    icon(if (image.removable) "close" else if (image.enableable) "add" else "block") {
                                        size = IconSize.SMALL
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}