@file:Suppress("RedundantVisibilityModifier")

package vegasful.admin.account


import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.plus
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import kotlin.time.Duration.Companion.seconds

private val tokenParser = Json {
    ignoreUnknownKeys = true
}

/**
 * Provides a browser based API for interacting with Cognito.
 */
public class CognitoClient(
    private val authUrl: String,
    private val clientId: String,
    private val redirectUrl: String,
    private val logoutUrl: String? = null,
    private val loginRedirectStorageKey: String? = null
) {
    public var accessToken: String? = null
    private var refreshToken: String? = null
    private var expiration: Instant = Clock.System.now()

    private val expirationJob = CoroutineScope(Dispatchers.Default)

    private val jsonClient = HttpClient(JsClient()) {
        install(ContentNegotiation) {
            json(Json {
                ignoreUnknownKeys = true
            })
        }
    }

    public val rawIdToken: String? get() = localStorage.get("id_token")

    public val idToken: IdToken? by lazy {
        localStorage.get("id_token")?.split(".")?.get(1)?.let {
            try {
                tokenParser.decodeFromString(IdToken.serializer(), window.atob(it))
            } catch (t: Throwable) {
                null
            }
        }
    }

    public fun isLoggedIn(): Boolean {
        return accessToken != null && expiration > Clock.System.now()
    }

    private suspend fun checkExpirationAsync() = coroutineScope {
        expirationJob.launch(Dispatchers.Main) {
            while (true) {
                delay(10.seconds)

                if (Clock.System.now() > expiration) {
                    if (refreshToken != null) {
                        refresh(refreshToken!!)
                        if (accessToken == null) {
                            login()
                        }
                    }
                }
            }
        }
    }

    public suspend fun init() {
        val code = Url(window.location.href).parameters.get("code")
        if (code != null) {
            exchangeCodeToTokens(code)

            val redirect: String? = loginRedirectStorageKey?.let { key ->
                localStorage[key]?.also {
                    localStorage.removeItem(key)
                }
            }
            if (redirect != null) {
                window.location.href = redirect
            } else {
                with(window.location) {
                    "$origin$pathname"
                }.let {
                    window.history.replaceState(url = it, data = null, title = "")
                }
            }
        } else {
            accessToken = localStorage.get("access_token")
            refreshToken = localStorage.get("refresh_token")
            expiration = localStorage.get("expiration")?.let {
                Instant.parse(it)
            } ?: Clock.System.now()

            if (accessToken != null && refreshToken != null && expiration < Clock.System.now()) {
                refresh(refreshToken!!)
            }
        }
        checkExpirationAsync()
    }

    /**
     * Redirects to the hosted login page.
     */
    public fun login() {
        val url = URLBuilder("$authUrl/login").apply {
            this.parameters.apply {
                set("client_id", clientId)
                set("response_type", "code")
                set("scope", "email openid phone")
                set("redirect_uri", redirectUrl)
            }
        }.buildString()

        window.location.href = url
    }

    /**
     * Clears all tokens.
     */
    public fun logout() {
        localStorage.removeItem("access_token")
        localStorage.removeItem("id_token")
        localStorage.removeItem("refresh_token")

        accessToken = null
        refreshToken = null
        expiration = Clock.System.now()

        val url = URLBuilder("$authUrl/logout").apply {
            this.parameters.apply {
                set("client_id", clientId)
                set("response_type", "code")
                set("scope", "email openid phone")
                if (logoutUrl != null) {
                    set("logout_uri", logoutUrl)
                } else {
                    set("redirect_uri", redirectUrl)
                }
            }
        }.buildString()

        window.location.href = url
    }

    private suspend fun refresh(refreshToken: String) {
        try {
            val token = jsonClient.submitForm("$authUrl/oauth2/token",
                formParameters = Parameters.build {
                    append("grant_type", "refresh_token")
                    append("client_id", clientId)
                    append("redirect_uri", redirectUrl)
                    append("refresh_token", refreshToken)
                }) {
                expectSuccess = true
            }.body<Token>()

            processToken(token)
        } catch (e: ClientRequestException) {
            // if refresh fails, clear everything
            accessToken = null
            this.refreshToken = null
            expiration = Clock.System.now()
            localStorage.removeItem("access_token")
            localStorage.removeItem("id_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("expiration")
        }
    }

    private fun processToken(token: Token) {
        localStorage.setItem("access_token", token.access_token)
        localStorage.setItem("id_token", token.id_token)
        accessToken = token.access_token

        token.refresh_token?.let {
            localStorage.setItem("refresh_token", token.refresh_token)
            refreshToken = it
        }
        expiration = Clock.System.now().plus(token.expires_in, DateTimeUnit.SECOND)
        localStorage.setItem("expiration", expiration.toString())
    }

    private suspend fun exchangeCodeToTokens(code: String): String {
        val result = jsonClient.submitForm("$authUrl/oauth2/token",
            formParameters = Parameters.build {
                append("grant_type", "authorization_code")
                append("client_id", clientId)
                append("redirect_uri", redirectUrl)
                append("code", code)
            }).body<Token>()

        processToken(result)

        return result.access_token
    }
}

@Serializable
public data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int
)


@Serializable
public data class IdToken(
    val sub: String,

    @SerialName("cognito:groups")
    val cognitoGroups: List<String>,

    val email: String? = null
)