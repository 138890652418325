@file:Suppress("ComplexRedundantLet", "SimpleRedundantLet", "unused", "UnnecessaryVariable",
    "NestedLambdaShadowedImplicitParameter", "PropertyName")

package vegasful.admin.api.client

import com.steamstreet.graphkt.ID
import com.steamstreet.graphkt.client.GraphQLResponse
import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.NullPointerException
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.booleanOrNull
import kotlinx.serialization.json.contentOrNull
import kotlinx.serialization.json.floatOrNull
import kotlinx.serialization.json.intOrNull
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import vegasful.admin.api.VenueStatus

class Query(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val venues: Venues
    get() {
      response.throwIfError("venues")
      val result = element["venues"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venues(response.forElement("venues"), it)
        }
      }
      return result ?: throw NullPointerException("Query: venues")
    }

  val events: Events
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Events(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Query: events")
    }

  val performers: Performers
    get() {
      response.throwIfError("performers")
      val result = element["performers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Performers(response.forElement("performers"), it)
        }
      }
      return result ?: throw NullPointerException("Query: performers")
    }

  val tags: Tags
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Tags(response.forElement("tags"), it)
        }
      }
      return result ?: throw NullPointerException("Query: tags")
    }

  val articles: Articles
    get() {
      response.throwIfError("articles")
      val result = element["articles"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Articles(response.forElement("articles"), it)
        }
      }
      return result ?: throw NullPointerException("Query: articles")
    }

  val search: GlobalSearchResults
    get() {
      response.throwIfError("search")
      val result = element["search"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          GlobalSearchResults(response.forElement("search"), it)
        }
      }
      return result ?: throw NullPointerException("Query: search")
    }

  val subscriptions: Subscriptions
    get() {
      response.throwIfError("subscriptions")
      val result = element["subscriptions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Subscriptions(response.forElement("subscriptions"), it)
        }
      }
      return result ?: throw NullPointerException("Query: subscriptions")
    }

  val entity: Entity
    get() {
      response.throwIfError("entity")
      val result = element["entity"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EntityImpl(response.forElement("entity"), it)
        }
      }
      return result ?: throw NullPointerException("Query: entity")
    }

  val permissions: List<String>
    get() {
      response.throwIfError("permissions")
      val result = element["permissions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result ?: throw NullPointerException("Query: permissions")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Mutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val admin: AdminMutation
    get() {
      response.throwIfError("admin")
      val result = element["admin"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminMutation(response.forElement("admin"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: admin")
    }

  val subscriptions: SubscriptionsMutator
    get() {
      response.throwIfError("subscriptions")
      val result = element["subscriptions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          SubscriptionsMutator(response.forElement("subscriptions"), it)
        }
      }
      return result ?: throw NullPointerException("Mutation: subscriptions")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class GlobalSearchResults(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val events: List<Event>?
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Event(response.forElement("events"), it)
          }
        }
      }
      return result
    }

  val venues: List<Venue>?
    get() {
      response.throwIfError("venues")
      val result = element["venues"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Venue(response.forElement("venues"), it)
          }
        }
      }
      return result
    }

  val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  val performers: List<Performer>?
    get() {
      response.throwIfError("performers")
      val result = element["performers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Performer(response.forElement("performers"), it)
          }
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

interface Entity {
  val __typename: String

  val id: ID

  val name: String?

  val description: String?

  val images: List<Image>?

  val tags: List<Tag>?

  val path: String?

  val events: EventSearchResults
}

class EntityImpl(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Entity: id")
    }

  override val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  override val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  override val path: String?
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Entity: events")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Venues(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val root: Venue
    get() {
      response.throwIfError("root")
      val result = element["root"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("root"), it)
        }
      }
      return result ?: throw NullPointerException("Venues: root")
    }

  val venue: Venue
    get() {
      response.throwIfError("venue")
      val result = element["venue"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("venue"), it)
        }
      }
      return result ?: throw NullPointerException("Venues: venue")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Venue(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Venue: id")
    }

  override val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Venue: name")
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val parent: Venue?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("parent"), it)
        }
      }
      return result
    }

  val links: List<SocialLink>
    get() {
      response.throwIfError("links")
      val result = element["links"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            SocialLink(response.forElement("links"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Venue: links")
    }

  override val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Venue: images")
    }

  override val tags: List<Tag>
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Venue: tags")
    }

  val children: List<Venue>
    get() {
      response.throwIfError("children")
      val result = element["children"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Venue(response.forElement("children"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Venue: children")
    }

  val location: GPSCoordinates?
    get() {
      response.throwIfError("location")
      val result = element["location"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          GPSCoordinates(response.forElement("location"), it)
        }
      }
      return result
    }

  val address: Address?
    get() {
      response.throwIfError("address")
      val result = element["address"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Address(response.forElement("address"), it)
        }
      }
      return result
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Venue: events")
    }

  override val path: String
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Venue: path")
    }

  val status: VenueStatus
    get() {
      response.throwIfError("status")
      val result = element["status"]?.takeIf { it !is JsonNull }?.let {
        VenueStatus.valueOf(it.jsonPrimitive.content)
      }
      return result ?: throw NullPointerException("Venue: status")
    }

  val redirect: String?
    get() {
      response.throwIfError("redirect")
      val result = element["redirect"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val ticketsUrl: String?
    get() {
      response.throwIfError("ticketsUrl")
      val result = element["ticketsUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class SocialLink(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val type: String
    get() {
      response.throwIfError("type")
      val result = element["type"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("SocialLink: type")
    }

  val locator: String
    get() {
      response.throwIfError("locator")
      val result = element["locator"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("SocialLink: locator")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Address(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val street: String?
    get() {
      response.throwIfError("street")
      val result = element["street"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val city: String?
    get() {
      response.throwIfError("city")
      val result = element["city"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val state: String?
    get() {
      response.throwIfError("state")
      val result = element["state"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val zip: String?
    get() {
      response.throwIfError("zip")
      val result = element["zip"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val country: String?
    get() {
      response.throwIfError("country")
      val result = element["country"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class GPSCoordinates(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val latitude: Float
    get() {
      response.throwIfError("latitude")
      val result = element["latitude"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.floatOrNull
      }
      return result ?: throw NullPointerException("GPSCoordinates: latitude")
    }

  val longitude: Float
    get() {
      response.throwIfError("longitude")
      val result = element["longitude"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.floatOrNull
      }
      return result ?: throw NullPointerException("GPSCoordinates: longitude")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Image(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: String
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Image: id")
    }

  val uri: String
    get() {
      response.throwIfError("uri")
      val result = element["uri"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Image: uri")
    }

  val width: Int?
    get() {
      response.throwIfError("width")
      val result = element["width"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val height: Int?
    get() {
      response.throwIfError("height")
      val result = element["height"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val withWidth: Image
    get() {
      response.throwIfError("withWidth")
      val result = element["withWidth"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Image(response.forElement("withWidth"), it)
        }
      }
      return result ?: throw NullPointerException("Image: withWidth")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Tags(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val tag: Tag
    get() {
      response.throwIfError("tag")
      val result = element["tag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Tag(response.forElement("tag"), it)
        }
      }
      return result ?: throw NullPointerException("Tags: tag")
    }

  val root: List<Tag>
    get() {
      response.throwIfError("root")
      val result = element["root"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("root"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Tags: root")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Tag(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Tag: id")
    }

  override val path: String
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Tag: path")
    }

  override val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Tag: name")
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val pageTitle: String
    get() {
      response.throwIfError("pageTitle")
      val result = element["pageTitle"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Tag: pageTitle")
    }

  override val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Tag: images")
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Tag: events")
    }

  override val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Events(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val event: Event
    get() {
      response.throwIfError("event")
      val result = element["event"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Event(response.forElement("event"), it)
        }
      }
      return result ?: throw NullPointerException("Events: event")
    }

  val search: EventSearchResults
    get() {
      response.throwIfError("search")
      val result = element["search"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("search"), it)
        }
      }
      return result ?: throw NullPointerException("Events: search")
    }

  val recentlyActive: EventSearchResults
    get() {
      response.throwIfError("recentlyActive")
      val result = element["recentlyActive"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("recentlyActive"), it)
        }
      }
      return result ?: throw NullPointerException("Events: recentlyActive")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Event(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Event: id")
    }

  override val path: String
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Event: path")
    }

  override val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Event: name")
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val venue: Venue?
    get() {
      response.throwIfError("venue")
      val result = element["venue"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("venue"), it)
        }
      }
      return result
    }

  override val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Event: images")
    }

  override val tags: List<Tag>
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Event: tags")
    }

  val date: String?
    get() {
      response.throwIfError("date")
      val result = element["date"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val time: String?
    get() {
      response.throwIfError("time")
      val result = element["time"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val endDate: String?
    get() {
      response.throwIfError("endDate")
      val result = element["endDate"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val endTime: String?
    get() {
      response.throwIfError("endTime")
      val result = element["endTime"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val duration: String?
    get() {
      response.throwIfError("duration")
      val result = element["duration"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val performers: List<Performer>
    get() {
      response.throwIfError("performers")
      val result = element["performers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Performer(response.forElement("performers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Event: performers")
    }

  val ticketsUrl: String?
    get() {
      response.throwIfError("ticketsUrl")
      val result = element["ticketsUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val disabled: Boolean
    get() {
      response.throwIfError("disabled")
      val result = element["disabled"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("Event: disabled")
    }

  val sourceUrl: String?
    get() {
      response.throwIfError("sourceUrl")
      val result = element["sourceUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val parent: Event?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Event(response.forElement("parent"), it)
        }
      }
      return result
    }

  val children: List<Event>
    get() {
      response.throwIfError("children")
      val result = element["children"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Event(response.forElement("children"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Event: children")
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Event: events")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Performers(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val performer: Performer
    get() {
      response.throwIfError("performer")
      val result = element["performer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Performer(response.forElement("performer"), it)
        }
      }
      return result ?: throw NullPointerException("Performers: performer")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Performer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Performer: id")
    }

  override val path: String
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Performer: path")
    }

  override val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Performer: name")
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val tags: List<Tag>
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Performer: tags")
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Performer: events")
    }

  override val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Performer: images")
    }

  val links: List<SocialLink>
    get() {
      response.throwIfError("links")
      val result = element["links"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            SocialLink(response.forElement("links"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Performer: links")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class ArticleSearchResult(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val articles: List<Article>
    get() {
      response.throwIfError("articles")
      val result = element["articles"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Article(response.forElement("articles"), it)
          }
        }
      }
      return result ?: throw NullPointerException("ArticleSearchResult: articles")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Articles(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val article: Article
    get() {
      response.throwIfError("article")
      val result = element["article"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Article(response.forElement("article"), it)
        }
      }
      return result ?: throw NullPointerException("Articles: article")
    }

  val recent: ArticleSearchResult
    get() {
      response.throwIfError("recent")
      val result = element["recent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ArticleSearchResult(response.forElement("recent"), it)
        }
      }
      return result ?: throw NullPointerException("Articles: recent")
    }

  val query: ArticleSearchResult
    get() {
      response.throwIfError("query")
      val result = element["query"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          ArticleSearchResult(response.forElement("query"), it)
        }
      }
      return result ?: throw NullPointerException("Articles: query")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Article(
  private val response: GraphQLResponse,
  private val element: JsonObject
) : Entity {
  override val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  override val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: id")
    }

  override val path: String?
    get() {
      response.throwIfError("path")
      val result = element["path"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val title: String
    get() {
      response.throwIfError("title")
      val result = element["title"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: title")
    }

  val summary: String
    get() {
      response.throwIfError("summary")
      val result = element["summary"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: summary")
    }

  override val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  override val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  val content: String
    get() {
      response.throwIfError("content")
      val result = element["content"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: content")
    }

  val created: String
    get() {
      response.throwIfError("created")
      val result = element["created"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: created")
    }

  val updated: String
    get() {
      response.throwIfError("updated")
      val result = element["updated"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Article: updated")
    }

  val published: String?
    get() {
      response.throwIfError("published")
      val result = element["published"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val linkedEntities: List<Entity>
    get() {
      response.throwIfError("linkedEntities")
      val result = element["linkedEntities"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            EntityImpl(response.forElement("linkedEntities"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Article: linkedEntities")
    }

  override val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("Article: images")
    }

  override val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("Article: events")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class EventSearchResults(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val events: List<Event>
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Event(response.forElement("events"), it)
          }
        }
      }
      return result ?: throw NullPointerException("EventSearchResults: events")
    }

  val pageToken: String?
    get() {
      response.throwIfError("pageToken")
      val result = element["pageToken"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class SystemMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val applicationEvent: Boolean
    get() {
      response.throwIfError("applicationEvent")
      val result = element["applicationEvent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("SystemMutation: applicationEvent")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Subscriptions(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val userSubscriptions: UserSubscriptions
    get() {
      response.throwIfError("userSubscriptions")
      val result = element["userSubscriptions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          UserSubscriptions(response.forElement("userSubscriptions"), it)
        }
      }
      return result ?: throw NullPointerException("Subscriptions: userSubscriptions")
    }

  val mine: UserSubscriptions?
    get() {
      response.throwIfError("mine")
      val result = element["mine"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          UserSubscriptions(response.forElement("mine"), it)
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class UserSubscriptions(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val subscriptions: List<Subscription>
    get() {
      response.throwIfError("subscriptions")
      val result = element["subscriptions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Subscription(response.forElement("subscriptions"), it)
          }
        }
      }
      return result ?: throw NullPointerException("UserSubscriptions: subscriptions")
    }

  val subscription: Subscription?
    get() {
      response.throwIfError("subscription")
      val result = element["subscription"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Subscription(response.forElement("subscription"), it)
        }
      }
      return result
    }

  val events: EventSearchResults
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EventSearchResults(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("UserSubscriptions: events")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class Subscription(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val subscriber: String
    get() {
      response.throwIfError("subscriber")
      val result = element["subscriber"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Subscription: subscriber")
    }

  val target: Entity
    get() {
      response.throwIfError("target")
      val result = element["target"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          EntityImpl(response.forElement("target"), it)
        }
      }
      return result ?: throw NullPointerException("Subscription: target")
    }

  val date: String
    get() {
      response.throwIfError("date")
      val result = element["date"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Subscription: date")
    }

  val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("Subscription: name")
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class SubscriptionsMutator(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val subscribe: Subscription
    get() {
      response.throwIfError("subscribe")
      val result = element["subscribe"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Subscription(response.forElement("subscribe"), it)
        }
      }
      return result ?: throw NullPointerException("SubscriptionsMutator: subscribe")
    }

  val unsubscribe: Boolean
    get() {
      response.throwIfError("unsubscribe")
      val result = element["unsubscribe"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("SubscriptionsMutator: unsubscribe")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminMutation(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val venues: AdminVenues
    get() {
      response.throwIfError("venues")
      val result = element["venues"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenues(response.forElement("venues"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: venues")
    }

  val events: AdminEvents
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEvents(response.forElement("events"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: events")
    }

  val performers: AdminPerformers
    get() {
      response.throwIfError("performers")
      val result = element["performers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformers(response.forElement("performers"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: performers")
    }

  val articles: AdminArticles
    get() {
      response.throwIfError("articles")
      val result = element["articles"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticles(response.forElement("articles"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: articles")
    }

  val tags: AdminTags
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTags(response.forElement("tags"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: tags")
    }

  val images: AdminImages
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminImages(response.forElement("images"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: images")
    }

  val loaders: AdminLoaders
    get() {
      response.throwIfError("loaders")
      val result = element["loaders"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoaders(response.forElement("loaders"), it)
        }
      }
      return result ?: throw NullPointerException("AdminMutation: loaders")
    }

  val domain: String?
    get() {
      response.throwIfError("domain")
      val result = element["domain"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminImages(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val upload: Image
    get() {
      response.throwIfError("upload")
      val result = element["upload"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Image(response.forElement("upload"), it)
        }
      }
      return result ?: throw NullPointerException("AdminImages: upload")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminVenues(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val venue: AdminVenue
    get() {
      response.throwIfError("venue")
      val result = element["venue"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenue(response.forElement("venue"), it)
        }
      }
      return result ?: throw NullPointerException("AdminVenues: venue")
    }

  val add: AdminVenue
    get() {
      response.throwIfError("add")
      val result = element["add"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenue(response.forElement("add"), it)
        }
      }
      return result ?: throw NullPointerException("AdminVenues: add")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminVenue(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminVenue: id")
    }

  val parent: Venue?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("parent"), it)
        }
      }
      return result
    }

  val children: List<Venue>
    get() {
      response.throwIfError("children")
      val result = element["children"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Venue(response.forElement("children"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminVenue: children")
    }

  val layers: List<AdminVenueLayer>
    get() {
      response.throwIfError("layers")
      val result = element["layers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminVenueLayer(response.forElement("layers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminVenue: layers")
    }

  val layer: AdminVenueLayer?
    get() {
      response.throwIfError("layer")
      val result = element["layer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenueLayer(response.forElement("layer"), it)
        }
      }
      return result
    }

  val local: AdminVenueLayer
    get() {
      response.throwIfError("local")
      val result = element["local"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenueLayer(response.forElement("local"), it)
        }
      }
      return result ?: throw NullPointerException("AdminVenue: local")
    }

  val resolved: Venue
    get() {
      response.throwIfError("resolved")
      val result = element["resolved"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("resolved"), it)
        }
      }
      return result ?: throw NullPointerException("AdminVenue: resolved")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminVenueLayer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val address: Address?
    get() {
      response.throwIfError("address")
      val result = element["address"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Address(response.forElement("address"), it)
        }
      }
      return result
    }

  val location: GPSCoordinates?
    get() {
      response.throwIfError("location")
      val result = element["location"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          GPSCoordinates(response.forElement("location"), it)
        }
      }
      return result
    }

  val links: List<SocialLink>?
    get() {
      response.throwIfError("links")
      val result = element["links"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            SocialLink(response.forElement("links"), it)
          }
        }
      }
      return result
    }

  val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  val images: List<Image>
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminVenueLayer: images")
    }

  val aliases: List<String>?
    get() {
      response.throwIfError("aliases")
      val result = element["aliases"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result
    }

  val ticketsUrl: String?
    get() {
      response.throwIfError("ticketsUrl")
      val result = element["ticketsUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val redirect: Venue?
    get() {
      response.throwIfError("redirect")
      val result = element["redirect"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("redirect"), it)
        }
      }
      return result
    }

  val status: VenueStatus?
    get() {
      response.throwIfError("status")
      val result = element["status"]?.takeIf { it !is JsonNull }?.let {
        VenueStatus.valueOf(it.jsonPrimitive.content)
      }
      return result
    }

  val update: AdminVenueLayer
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminVenueLayer(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminVenueLayer: update")
    }

  val rankingScore: Int?
    get() {
      response.throwIfError("rankingScore")
      val result = element["rankingScore"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminPerformers(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val performer: AdminPerformer
    get() {
      response.throwIfError("performer")
      val result = element["performer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformer(response.forElement("performer"), it)
        }
      }
      return result ?: throw NullPointerException("AdminPerformers: performer")
    }

  val add: AdminPerformer
    get() {
      response.throwIfError("add")
      val result = element["add"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformer(response.forElement("add"), it)
        }
      }
      return result ?: throw NullPointerException("AdminPerformers: add")
    }

  val sourceSearch: List<SourceDataSearchResult>
    get() {
      response.throwIfError("sourceSearch")
      val result = element["sourceSearch"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            SourceDataSearchResult(response.forElement("sourceSearch"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminPerformers: sourceSearch")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class SourceDataSearchResult(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("SourceDataSearchResult: id")
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminPerformer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminPerformer: id")
    }

  val layers: List<AdminPerformerLayer?>
    get() {
      response.throwIfError("layers")
      val result = element["layers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminPerformerLayer(response.forElement("layers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminPerformer: layers")
    }

  val layer: AdminPerformerLayer?
    get() {
      response.throwIfError("layer")
      val result = element["layer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformerLayer(response.forElement("layer"), it)
        }
      }
      return result
    }

  val local: AdminPerformerLayer
    get() {
      response.throwIfError("local")
      val result = element["local"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformerLayer(response.forElement("local"), it)
        }
      }
      return result ?: throw NullPointerException("AdminPerformer: local")
    }

  val resolved: Performer
    get() {
      response.throwIfError("resolved")
      val result = element["resolved"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Performer(response.forElement("resolved"), it)
        }
      }
      return result ?: throw NullPointerException("AdminPerformer: resolved")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminPerformerLayer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val aliases: List<String>?
    get() {
      response.throwIfError("aliases")
      val result = element["aliases"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result
    }

  val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  val wikiIdentifier: String?
    get() {
      response.throwIfError("wikiIdentifier")
      val result = element["wikiIdentifier"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val rankingScore: Int?
    get() {
      response.throwIfError("rankingScore")
      val result = element["rankingScore"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val update: AdminPerformerLayer
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminPerformerLayer(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminPerformerLayer: update")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminEvents(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val event: AdminEvent
    get() {
      response.throwIfError("event")
      val result = element["event"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEvent(response.forElement("event"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEvents: event")
    }

  val reviewNext: AdminEvent?
    get() {
      response.throwIfError("reviewNext")
      val result = element["reviewNext"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEvent(response.forElement("reviewNext"), it)
        }
      }
      return result
    }

  val add: AdminEvent
    get() {
      response.throwIfError("add")
      val result = element["add"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEvent(response.forElement("add"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEvents: add")
    }

  val forReview: List<Event>
    get() {
      response.throwIfError("forReview")
      val result = element["forReview"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Event(response.forElement("forReview"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminEvents: forReview")
    }

  val merge: AdminEvent
    get() {
      response.throwIfError("merge")
      val result = element["merge"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEvent(response.forElement("merge"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEvents: merge")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminEvent(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminEvent: id")
    }

  val layers: List<AdminEventLayer>
    get() {
      response.throwIfError("layers")
      val result = element["layers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminEventLayer(response.forElement("layers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminEvent: layers")
    }

  val layer: AdminEventLayer?
    get() {
      response.throwIfError("layer")
      val result = element["layer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEventLayer(response.forElement("layer"), it)
        }
      }
      return result
    }

  val local: AdminEventLayer
    get() {
      response.throwIfError("local")
      val result = element["local"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEventLayer(response.forElement("local"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEvent: local")
    }

  val resolved: Event
    get() {
      response.throwIfError("resolved")
      val result = element["resolved"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Event(response.forElement("resolved"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEvent: resolved")
    }

  val parent: Event?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Event(response.forElement("parent"), it)
        }
      }
      return result
    }

  val children: List<Event>
    get() {
      response.throwIfError("children")
      val result = element["children"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Event(response.forElement("children"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminEvent: children")
    }

  val review: String?
    get() {
      response.throwIfError("review")
      val result = element["review"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val rebuild: Boolean
    get() {
      response.throwIfError("rebuild")
      val result = element["rebuild"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("AdminEvent: rebuild")
    }

  val disable: Boolean
    get() {
      response.throwIfError("disable")
      val result = element["disable"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("AdminEvent: disable")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminEventLayer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val layerId: String?
    get() {
      response.throwIfError("layerId")
      val result = element["layerId"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val date: String?
    get() {
      response.throwIfError("date")
      val result = element["date"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val time: String?
    get() {
      response.throwIfError("time")
      val result = element["time"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val duration: Int?
    get() {
      response.throwIfError("duration")
      val result = element["duration"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val expiration: String?
    get() {
      response.throwIfError("expiration")
      val result = element["expiration"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val ticketsUrl: String?
    get() {
      response.throwIfError("ticketsUrl")
      val result = element["ticketsUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val sourceUrl: String?
    get() {
      response.throwIfError("sourceUrl")
      val result = element["sourceUrl"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val aliases: List<String>?
    get() {
      response.throwIfError("aliases")
      val result = element["aliases"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonPrimitive.content
        }
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val excludedImages: List<Image>
    get() {
      response.throwIfError("excludedImages")
      val result = element["excludedImages"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("excludedImages"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminEventLayer: excludedImages")
    }

  val tags: List<Tag>?
    get() {
      response.throwIfError("tags")
      val result = element["tags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("tags"), it)
          }
        }
      }
      return result
    }

  val excludedTags: List<Tag>
    get() {
      response.throwIfError("excludedTags")
      val result = element["excludedTags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("excludedTags"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminEventLayer: excludedTags")
    }

  val performers: List<Performer>?
    get() {
      response.throwIfError("performers")
      val result = element["performers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Performer(response.forElement("performers"), it)
          }
        }
      }
      return result
    }

  val venue: Venue?
    get() {
      response.throwIfError("venue")
      val result = element["venue"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Venue(response.forElement("venue"), it)
        }
      }
      return result
    }

  val lastUpdated: String?
    get() {
      response.throwIfError("lastUpdated")
      val result = element["lastUpdated"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val update: AdminEventLayer
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminEventLayer(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminEventLayer: update")
    }

  val excluded: Boolean?
    get() {
      response.throwIfError("excluded")
      val result = element["excluded"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val rankingScore: Int?
    get() {
      response.throwIfError("rankingScore")
      val result = element["rankingScore"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminArticles(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val article: AdminArticle
    get() {
      response.throwIfError("article")
      val result = element["article"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("article"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticles: article")
    }

  val new: AdminArticle
    get() {
      response.throwIfError("new")
      val result = element["new"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("new"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticles: new")
    }

  val drafts: List<AdminArticle>
    get() {
      response.throwIfError("drafts")
      val result = element["drafts"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminArticle(response.forElement("drafts"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminArticles: drafts")
    }

  val content: AdminArticle
    get() {
      response.throwIfError("content")
      val result = element["content"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("content"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticles: content")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminArticle(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminArticle: id")
    }

  val title: String?
    get() {
      response.throwIfError("title")
      val result = element["title"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val summary: String?
    get() {
      response.throwIfError("summary")
      val result = element["summary"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val content: String?
    get() {
      response.throwIfError("content")
      val result = element["content"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val links: List<Entity>?
    get() {
      response.throwIfError("links")
      val result = element["links"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            EntityImpl(response.forElement("links"), it)
          }
        }
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val created: String
    get() {
      response.throwIfError("created")
      val result = element["created"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminArticle: created")
    }

  val updated: String
    get() {
      response.throwIfError("updated")
      val result = element["updated"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminArticle: updated")
    }

  val published: String?
    get() {
      response.throwIfError("published")
      val result = element["published"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val update: AdminArticle
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticle: update")
    }

  val publish: AdminArticle
    get() {
      response.throwIfError("publish")
      val result = element["publish"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("publish"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticle: publish")
    }

  val unpublish: AdminArticle
    get() {
      response.throwIfError("unpublish")
      val result = element["unpublish"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminArticle(response.forElement("unpublish"), it)
        }
      }
      return result ?: throw NullPointerException("AdminArticle: unpublish")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminTags(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val tag: AdminTag
    get() {
      response.throwIfError("tag")
      val result = element["tag"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTag(response.forElement("tag"), it)
        }
      }
      return result ?: throw NullPointerException("AdminTags: tag")
    }

  val add: AdminTag
    get() {
      response.throwIfError("add")
      val result = element["add"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTag(response.forElement("add"), it)
        }
      }
      return result ?: throw NullPointerException("AdminTags: add")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminTag(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminTag: id")
    }

  val layers: List<AdminTagLayer?>
    get() {
      response.throwIfError("layers")
      val result = element["layers"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminTagLayer(response.forElement("layers"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminTag: layers")
    }

  val layer: AdminTagLayer?
    get() {
      response.throwIfError("layer")
      val result = element["layer"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTagLayer(response.forElement("layer"), it)
        }
      }
      return result
    }

  val local: AdminTagLayer
    get() {
      response.throwIfError("local")
      val result = element["local"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTagLayer(response.forElement("local"), it)
        }
      }
      return result ?: throw NullPointerException("AdminTag: local")
    }

  val resolved: Tag
    get() {
      response.throwIfError("resolved")
      val result = element["resolved"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Tag(response.forElement("resolved"), it)
        }
      }
      return result ?: throw NullPointerException("AdminTag: resolved")
    }

  val children: List<Tag>?
    get() {
      response.throwIfError("children")
      val result = element["children"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("children"), it)
          }
        }
      }
      return result
    }

  val parent: Tag?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Tag(response.forElement("parent"), it)
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminTagLayer(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val name: String?
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val description: String?
    get() {
      response.throwIfError("description")
      val result = element["description"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val images: List<Image>?
    get() {
      response.throwIfError("images")
      val result = element["images"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Image(response.forElement("images"), it)
          }
        }
      }
      return result
    }

  val childLimit: Int?
    get() {
      response.throwIfError("childLimit")
      val result = element["childLimit"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val parent: Tag?
    get() {
      response.throwIfError("parent")
      val result = element["parent"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          Tag(response.forElement("parent"), it)
        }
      }
      return result
    }

  val shadow: Boolean?
    get() {
      response.throwIfError("shadow")
      val result = element["shadow"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val impliedTags: List<Tag>?
    get() {
      response.throwIfError("impliedTags")
      val result = element["impliedTags"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            Tag(response.forElement("impliedTags"), it)
          }
        }
      }
      return result
    }

  val pageTitle: String?
    get() {
      response.throwIfError("pageTitle")
      val result = element["pageTitle"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val rankingScore: Int?
    get() {
      response.throwIfError("rankingScore")
      val result = element["rankingScore"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.intOrNull
      }
      return result
    }

  val update: AdminTagLayer
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminTagLayer(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminTagLayer: update")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminLoaders(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val modules: List<AdminLoaderModule>
    get() {
      response.throwIfError("modules")
      val result = element["modules"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminLoaderModule(response.forElement("modules"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminLoaders: modules")
    }

  val module: AdminLoaderModule
    get() {
      response.throwIfError("module")
      val result = element["module"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoaderModule(response.forElement("module"), it)
        }
      }
      return result ?: throw NullPointerException("AdminLoaders: module")
    }

  val loaders: List<AdminLoader>
    get() {
      response.throwIfError("loaders")
      val result = element["loaders"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            AdminLoader(response.forElement("loaders"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminLoaders: loaders")
    }

  val loader: AdminLoader
    get() {
      response.throwIfError("loader")
      val result = element["loader"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoader(response.forElement("loader"), it)
        }
      }
      return result ?: throw NullPointerException("AdminLoaders: loader")
    }

  val registerLoader: AdminLoader
    get() {
      response.throwIfError("registerLoader")
      val result = element["registerLoader"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoader(response.forElement("registerLoader"), it)
        }
      }
      return result ?: throw NullPointerException("AdminLoaders: registerLoader")
    }

  val deregister: Boolean
    get() {
      response.throwIfError("deregister")
      val result = element["deregister"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("AdminLoaders: deregister")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminLoaderModule(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminLoaderModule: id")
    }

  val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminLoaderModule: name")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class AdminLoader(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val id: ID
    get() {
      response.throwIfError("id")
      val result = element["id"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminLoader: id")
    }

  val name: String
    get() {
      response.throwIfError("name")
      val result = element["name"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("AdminLoader: name")
    }

  val module: AdminLoaderModule?
    get() {
      response.throwIfError("module")
      val result = element["module"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoaderModule(response.forElement("module"), it)
        }
      }
      return result
    }

  val schedule: String?
    get() {
      response.throwIfError("schedule")
      val result = element["schedule"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val update: AdminLoader
    get() {
      response.throwIfError("update")
      val result = element["update"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          AdminLoader(response.forElement("update"), it)
        }
      }
      return result ?: throw NullPointerException("AdminLoader: update")
    }

  val parameters: String?
    get() {
      response.throwIfError("parameters")
      val result = element["parameters"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val recentExecutions: List<LoaderExecution>
    get() {
      response.throwIfError("recentExecutions")
      val result = element["recentExecutions"]?.takeIf { it !is JsonNull }?.let {
        it.jsonArray.map {
          it.jsonObject.let {
            LoaderExecution(response.forElement("recentExecutions"), it)
          }
        }
      }
      return result ?: throw NullPointerException("AdminLoader: recentExecutions")
    }

  val execute: Boolean
    get() {
      response.throwIfError("execute")
      val result = element["execute"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result ?: throw NullPointerException("AdminLoader: execute")
    }

  val readInternalData: String?
    get() {
      response.throwIfError("readInternalData")
      val result = element["readInternalData"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result
    }

  val saveInternalData: Boolean?
    get() {
      response.throwIfError("saveInternalData")
      val result = element["saveInternalData"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.booleanOrNull
      }
      return result
    }

  val lastLoad: LoaderExecution?
    get() {
      response.throwIfError("lastLoad")
      val result = element["lastLoad"]?.takeIf { it !is JsonNull }?.let {
        it.jsonObject.let {
          LoaderExecution(response.forElement("lastLoad"), it)
        }
      }
      return result
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}

class LoaderExecution(
  private val response: GraphQLResponse,
  private val element: JsonObject
) {
  val __typename: String
    get() = element["__typename"]!!.jsonPrimitive.content

  val ts: String
    get() {
      response.throwIfError("ts")
      val result = element["ts"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("LoaderExecution: ts")
    }

  val events: String
    get() {
      response.throwIfError("events")
      val result = element["events"]?.takeIf { it !is JsonNull }?.let {
        it.jsonPrimitive.contentOrNull
      }
      return result ?: throw NullPointerException("LoaderExecution: events")
    }

  fun hasField(key: String): Boolean = element.containsKey(key)
}
