package vegasful.admin.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Theme

/**
 * Returned by the notification renderer, used to add notifications.
 */
interface NotificationsConfig {
    fun showError(message: String, description: String? = null)
}

/**
 * Renders the notifications for the application.
 */
@Composable
fun notifications(): NotificationsConfig {
    var messages by remember { mutableStateOf<List<Notification>>(emptyList()) }

    Div({ classes(NotificationStyles.container) }) {
        messages.forEach {
            notificationBox(it) {
                messages = messages - it
            }
        }

    }

    return object : NotificationsConfig {
        override fun showError(message: String, description: String?) {
            messages = messages + Notification(message, description)
        }
    }
}

private class Notification(
    val message: String,
    val description: String?
)


@Composable
private fun notificationBox(notification: Notification, remove: () -> Unit) {
    Div({ classes(NotificationStyles.box) }) {
        Div({ classes(NotificationStyles.boxMessage) }) {
            Text(notification.message)
        }

        Div({
            classes(NotificationStyles.boxClose)
            onClick {
                remove()
            }
        }) {
            icon("close") {
                size = IconSize.SMALL
            }
        }
    }
}


object NotificationStyles : StyleSheet() {
    val container by style {
        position(Position.Fixed)
        top(40.px)
        left(0.px)
        right(0.px)
        property("z-index", "20")
    }

    val box by style {
        backgroundColor(Theme.messageAlertBackground.value())
        border(1.px, LineStyle.Solid, Theme.messageAlertBorder.value())
        padding(1.cssRem)
        margin(5.px)
        position(Position.Relative)
    }

    val boxMessage by style {
        fontWeight(700)
    }

    val boxClose by style {
        position(Position.Absolute)
        top(5.px)
        right(5.px)
        cursor("pointer")
    }
}
