package vegasful.admin.components

/**
 * This should be moved to some shared location to be used by the loaders as well as the API
 */

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalTime
import kotlinx.serialization.Serializable

/**
 * Describes an event as provided by an event data provider.
 */
@Serializable
data class LoaderEvent(
    val id: String,
    val parentId: String? = null,

    val aliases: List<String>? = null,

    val name: String? = null,
    val description: String? = null,

    val images: List<LoaderImage>? = null,
    val venue: LoaderVenue? = null,

    val sourceUrl: String? = null,
    val ticketsUrl: String? = null,

    val startDate: String? = null,
    val startTime: String? = null,
    val endDate: String? = null,
    val endTime: String? = null,

    /**
     * In minutes
     */
    val duration: Int? = null,

    /**
     * A list of tags to assign
     */
    val tags: List<String>? = null,

    /**
     * A list of performers to assign
     */
    val performers: List<String>? = null,

    /**
     * A list of event repeat data
     */
    val schedule: List<ScheduleDescriptor>? = null
)

@Serializable
data class ScheduleDescriptor(
    val times: List<LocalTime>,
    /**
     * If start date is set, and daysOfWeek is empty, it will be assumed to be a single instance
     * of an event.
     */
    val startDate: LocalDate? = null,
    /**
     * For repeating events, the days of the week that the show is active.
     */
    val daysOfWeek: Set<Int> = emptySet(),
    /**
     * Starting now, the number of days forward to populate repeating events. For example, setting this to
     * 180 will cause the event to repeat for up to 6 months from the time the loader is run.
     */
    val repeatFor: Int? = null,

    /**
     * The end date for repeating. No events will be scheduled beyond this date.
     */
    val endDate: LocalDate? = null,

    /**
     * a list of dates that should be excluded from the repeat
     */
    val excludeDates: List<LocalDate>? = null
)

@Serializable
data class LoaderVenue(
    val id: String? = null,
    val alias: String? = null,
    val aliases: List<String>? = null
) {
    val allAliases: List<String>
        get() {
            return listOfNotNull(alias) + aliases.orEmpty()
        }

    companion object {
        fun fromAlias(alias: String): LoaderVenue {
            return LoaderVenue(alias = alias)
        }
    }
}

/**
 * A loader image is just a URL.
 */
@Serializable
data class LoaderImage(
    val url: String
)

enum class LoaderActionType {
    ADD,
    REMOVE,
    UPDATE
}

data class DiffProcessorResult(
    val processed: List<LoaderEvent>,
    val errors: List<DiffProcessorError>
)

data class DiffProcessorError(
    val event: LoaderEvent,
    val t: Throwable
)
