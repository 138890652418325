package vegasful.admin.views

import androidx.compose.runtime.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.api.client.Performer
import vegasful.admin.components.*

class PerformersBoxPerformer(
    val performer: Performer,
    val removable: Boolean
)

enum class PerformersOperation {
    ADD,
    REMOVE,
    EXCLUDE
}

enum class SearchStyle {
    PILL,
    BOX
}

@Composable
fun performersBox(
    performers: List<PerformersBoxPerformer>,
    eventId: String? = null,
    initialSearch: String? = null,
    operation: suspend (Performer, PerformersOperation) -> Unit
) {
    val coroutine = rememberCoroutineScope()
    var adding by remember { mutableStateOf(initialSearch != null) }
    var operatingOn by remember { mutableStateOf<String?>(null) }

    box({
        title = "Performers"
        paddedContent = false

        action("Add", true) {
            adding = true
        }
    }) {
        if (performers.isEmpty()) {
            boxMessage("No performers.")
        } else {
            table<PerformersBoxPerformer> {
                items(performers)
                column {
                    content {
                        A(href = "#content/performers/${it.performer.id}") {
                            Text(it.performer.name)
                        }
                    }
                }

                column {
                    width = 100.px
                    content { performer ->
                        Div({
                            style { textAlign("right") }
                        }) {
                            if (operatingOn == performer.performer.id) {
                                spinner()
                            } else {
                                icon("delete") {
                                    action {
                                        coroutine.launch {
                                            operatingOn = performer.performer.id
                                            operation(performer.performer, PerformersOperation.REMOVE)
                                            operatingOn = null
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (adding) {
            entitySearch<Performer> {
                withEntityResult {
                    performers {
                        id
                        name
                        path
                    }
                }
                selection = {
                    operation(it, PerformersOperation.ADD)
                }
                this.initialSearchInput = initialSearch
                showSpinnerOnClick = true

                add = { searchInput ->
                    Application.navigation.navigate {
                        path("content", "performers", "add")
                        if (eventId != null) {
                            parameters.append("event", eventId)
                        }
                        if (!searchInput.isNullOrBlank()) {
                            parameters.append("name", searchInput)
                        }
                    }
                }

                onClose = {
                    adding = false
                }
            }
        }
    }
}