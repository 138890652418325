package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.ArticleSearchInput
import vegasful.admin.api.client.AdminArticle
import vegasful.admin.api.client.Article
import vegasful.admin.api.client.query
import vegasful.admin.components.box
import vegasful.admin.components.entityView
import vegasful.admin.components.spinner
import vegasful.admin.components.table

@Composable
fun articles() {
    var recent by remember { mutableStateOf<List<Article>?>(null) }
    var drafts by remember { mutableStateOf<List<AdminArticle>?>(null) }

    LaunchedEffect("articles") {
        val recentJob = async {
            Application.api.query {
                articles {
                    recent(ArticleSearchInput(10)) {
                        articles {
                            id
                            title
                        }
                    }
                }
            }.articles.recent.articles
        }
        val draftsJob = async {
            Application.api.admin {
                articles {
                    drafts {
                        id
                        title
                    }
                }
            }.articles.drafts
        }

        awaitAll(recentJob, draftsJob)

        recent = recentJob.await()
        drafts = draftsJob.await()
    }

    contentContainer {
        entityView {
            title = "Articles"

            content {
                box({
                    title = "Recently Published"
                    paddedContent = false
                }) {
                    if (recent == null) {
                        spinner()
                    } else {
                        table<Article> {
                            displayHeader = false
                            items(recent.orEmpty())

                            column("Title") {
                                content {
                                    A(href = "#content/articles/${it.id}") {
                                        Text(it.title)
                                    }
                                }
                            }
                        }
                    }
                }
                box({
                    title = "Drafts"
                    paddedContent = false

                    action {
                        title = "New"
                        primary = true
                        this.action {
                            Application.navigation.navigate("content/articles/add")
                        }
                    }
                }) {
                    if (recent == null) {
                        spinner()
                    } else {
                        table<AdminArticle> {
                            displayHeader = false
                            items(drafts.orEmpty())

                            column("Title") {
                                content {
                                    A(href = "#content/articles/${it.id}") {
                                        Text(it.title.orEmpty())
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}