package vegasful.admin.views

import androidx.compose.runtime.*
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import vegasful.admin.Application
import vegasful.admin.admin
import vegasful.admin.api.ArticleInput
import vegasful.admin.api.client.AdminArticle
import vegasful.admin.api.client._AdminArticleQuery
import vegasful.admin.components.*

/**
 * The query to get the entire article
 */
fun _AdminArticleQuery.fullArticleQuery() {
    id
    content
    summary
    created
    published
    title
    images {
        this.id
        uri
    }
    links {
        __typename
        id
        name
        path
    }
}

@Composable
fun article(id: String?) {
    var articleId by remember { mutableStateOf(id) }
    var article by remember { mutableStateOf<AdminArticle?>(null) }
    var update by remember { mutableStateOf(ArticleInput()) }

    LaunchedEffect(id ?: "new") {
        if (id != null) {
            val result = Application.api.admin {
                articles {
                    article(id) {
                        fullArticleQuery()
                    }
                }
            }
            article = result.articles.article
        }
    }

    suspend fun save(input: ArticleInput) {
        if (id != null) {
            Application.api.admin {
                articles {
                    this.article(id) {
                        update(input) {
                            fullArticleQuery()
                        }
                    }
                }
            }.articles.article.update.let {
                article = it
            }
        } else {
            Application.api.admin {
                articles {
                    new(null, input) {
                        fullArticleQuery()
                    }
                }
            }.articles.article.update.let {
                article = it
                articleId = it.id
            }
        }
    }

    suspend fun unpublish() {
        if (id != null) {
            try {
                article = Application.api.admin {
                    articles {
                        this.article(id) {
                            unpublish {
                                fullArticleQuery()
                            }
                        }
                    }
                }.articles.article.unpublish
            } catch (e: Throwable) {
                Application.notifications.showError("The article failed to unpublish: ${e.message}")
            }
        }
    }

    suspend fun publish() {
        if (id != null) {
            try {
                article = Application.api.admin {
                    articles {
                        this.article(id) {
                            publish(Clock.System.now().toString()) {
                                fullArticleQuery()
                            }
                        }
                    }
                }.articles.article.unpublish
            } catch (e: Throwable) {
                Application.notifications.showError("The article failed to publish: ${e.message}")
            }
        }
    }

    contentContainer {
        entityView {
            title = if (id != null) article?.title else "New Article"

            breadcrumbs {
                crumb("Articles", "content/articles")
            }

            content {
                box({
                    if (article?.published != null) {
                        action {
                            title = "Unpublish"
                            showProgressOnAction = true
                            action {
                                unpublish()
                            }
                        }
                    } else if (id != null) {
                        action {
                            title = "Publish"
                            primary = true
                            showProgressOnAction = true
                            action {
                                publish()
                            }
                        }
                    }
                }) {

                    if (id != null) {
                        dialogField("ID") {
                            Input(InputType.Text) {
                                disabled()
                                value(id)
                            }
                        }
                    }

                    dialogField("Title") {
                        simpleTextField(update.title ?: article?.title) {
                            update = update.copy(title = it.takeIf { it != article?.title })
                        }
                    }

                    dialogField("Article") {
                        richTextEditor(update.content ?: article?.content) {
                            update = update.copy(content = it.takeIf { it != article?.content })
                        }
                    }

                    Div {
                        button {
                            title = "Save"
                            primary = true
                            showProgressOnAction = true
                            action {
                                save(update)
                                update = ArticleInput()
                            }
                        }

                        if (articleId != null) {
                            button("Revert") {
                                update = ArticleInput()
                            }
                        }
                    }
                }
            }
        }
    }
}